import React from 'react'
import styled from 'styled-components'
import { useTutorialEditorContext } from './useTutorialEditorContext'
import { Heading, Button } from 'ui'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default function QuizError() {
  const { generateQuiz } = useTutorialEditorContext()

  return (
    <Container>
      <Heading level={2}>
        Oops. We weren't able to generate your quiz. Please try again :(.
      </Heading>
      <Button width={200} onClick={generateQuiz}>
        Regenerate
      </Button>
    </Container>
  )
}
