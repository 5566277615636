import React, { useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import Content from './Content'
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Flex } from 'ui'

interface Props {
  keyPath: string
}

type SelectedContentType =
  | 'image'
  | 'pdf'
  | 'youtube'
  | 'paragraph'
  | 'bullet_list'
  | 'subtitle'

export default function Contents({ keyPath }: Props) {
  const { fields, move, insert, remove } = useFieldArray({
    name: keyPath,
  })
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
  const [activeId, setActiveId] = useState(null)

  const handleDragStart = (event) => {
    const { active } = event
    setActiveId(active.id)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex(
        (_, index) => `item-${index}` === active.id
      )
      const newIndex = fields.findIndex(
        (_, index) => `item-${index}` === over.id
      )
      move(oldIndex, newIndex)
    }

    setActiveId(null)
  }

  const [isAddContentOpen, setIsAddContentOpen] = useState(false)
  const [selectedContentType, setSelectedContentType] =
    useState<SelectedContentType | null>(null)

  useEffect(() => {
    if (selectedContentType) {
      let placeholderData
      switch (selectedContentType) {
        case 'image':
          placeholderData = {
            type: 'image_url',
            data: 'https://upload.wikimedia.org/wikipedia/en/thumb/2/27/Bliss_%28Windows_XP%29.png/270px-Bliss_%28Windows_XP%29.png',
          }
          break
        case 'pdf':
          placeholderData = {
            type: 'pdf_url',
            data: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
          }
          break
        case 'youtube':
          placeholderData = {
            type: 'video_youtube',
            data: 'https://www.youtube.com/embed/xfnd25a4Pcs',
          }
          break
        case 'paragraph':
          placeholderData = {
            type: 'paragraph',
            data: 'This is a paragraph. Edit this text to make it your own.',
          }
          break
        case 'bullet_list':
          placeholderData = {
            type: 'bullet_list',
            data: [
              'This is an example bullet. Edit this text to make it your own.',
            ],
          }
          break
        case 'subtitle':
          placeholderData = {
            type: 'subtitle',
            data: 'This is a subtitle. Edit this text to make it your own.',
          }
          break
        default:
          break
      }
      insert(fields.length, placeholderData)
      setSelectedContentType(null)
      setIsAddContentOpen(false)
    }
  }, [selectedContentType])

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={fields.map((_, index) => `item-${index}`)}
          strategy={verticalListSortingStrategy}
        >
          {fields.map((content, contentIdx) => {
            return (
              <Content
                key={`item-${contentIdx}`}
                id={`item-${contentIdx}`}
                keyPath={`${keyPath}.${contentIdx}`}
                onRemove={() => remove(contentIdx)}
              />
            )
          })}
        </SortableContext>
      </DndContext>
      <Flex
        pt={3}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <button
          type="button"
          onClick={() => setIsAddContentOpen((prev) => !prev)}
        >
          {isAddContentOpen ? 'Close Menu' : 'Add Content'}
        </button>
        {isAddContentOpen && (
          <Flex
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            pt={3}
          >
            <button
              type="button"
              onClick={() => setSelectedContentType('image')}
            >
              Image
            </button>
            <button type="button" onClick={() => setSelectedContentType('pdf')}>
              PDF URL
            </button>
            <button
              type="button"
              onClick={() => setSelectedContentType('youtube')}
            >
              YouTube Embed URL
            </button>
            <button
              type="button"
              onClick={() => setSelectedContentType('paragraph')}
            >
              Paragraph
            </button>
            <button
              type="button"
              onClick={() => setSelectedContentType('bullet_list')}
            >
              Bullet List
            </button>
            <button
              type="button"
              onClick={() => setSelectedContentType('subtitle')}
            >
              Subtitle
            </button>
          </Flex>
        )}
      </Flex>
    </>
  )
}
