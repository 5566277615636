import { useQuery, useQueryClient } from '@tanstack/react-query'
import { companyTutorialsKey } from './keys'
import { getCompanyTutorials } from 'api/company'

export function useCompanyTutorials() {
  return useQuery({
    queryKey: companyTutorialsKey,
    queryFn: getCompanyTutorials,
  })
}

export function useInvalidateCompanyTutorials() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: companyTutorialsKey,
      }),
  }
}
