import React from 'react'
import { MarkdownContent } from 'api/shift'
import {
  Flex,
  Icon,
  IconButton,
  MarkdownEditor,
  MarkdownProvider,
  Text,
} from '@workwhile/ui'
import { PlusIcon, Trash2Icon } from 'lucide-react'
import styled from 'styled-components'

interface Props {
  pages: MarkdownContent[]
  currentPageIdx: number
  onPageChange: (pageIdx: number) => void
  onAddPage: () => void
  onDeletePage: (pageIdx: number) => void
}

export function Paginator({
  pages,
  currentPageIdx,
  onPageChange,
  onAddPage,
  onDeletePage,
}: Props) {
  return (
    <Flex mb={3} overflow={'auto'}>
      {pages.map((page, index) => {
        const isCurrent = index === currentPageIdx
        return (
          <Flex
            flexDirection={'column'}
            alignItems={'center'}
            key={index}
            mr={3}
            flexShrink={0}
          >
            <PageWrapper
              data-page-number={index + 1}
              borderWidth={isCurrent ? 2 : 1}
              borderColor={isCurrent ? 'primary' : 'neutrals.200'}
              onClick={() => onPageChange(index)}
            >
              <RemoveButton
                onClick={(e) => {
                  e.stopPropagation()
                  onDeletePage(index)
                }}
              >
                <Icon icon={Trash2Icon} size={16} />
              </RemoveButton>
              <MarkdownProvider>
                <MarkdownEditor
                  defaultValue={page.markdown}
                  readonly={true}
                  borderless={true}
                  scale={0.4}
                />
              </MarkdownProvider>
            </PageWrapper>
            <Text fontSize={1} mt={1}>
              {index + 1}
            </Text>
          </Flex>
        )
      })}
      <PageWrapper
        key={'new-page'}
        alignItems={'center'}
        justifyContent={'center'}
        onClick={onAddPage}
      >
        <Icon icon={PlusIcon} size={40} color={'primary'} />
      </PageWrapper>
    </Flex>
  )
}

const RemoveButton = styled(IconButton).attrs({
  hoverBackgroundColor: 'transparent',
  hoverColor: 'error',
})`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  z-index: 1;
`

const PageWrapper = styled(Flex).attrs({
  position: 'relative',
  width: 90,
  height: 120,
  borderRadius: 'standard',
  overflow: 'hidden',
  borderStyle: 'solid',
  flexShrink: 0,
})`
  cursor: pointer;

  &:hover {
    ${RemoveButton} {
      display: block;
    }
  }
`

PageWrapper.defaultProps = {
  borderWidth: 1,
  borderColor: 'neutrals.200',
}
