import { api, graphql } from 'api'
import { path } from 'ramda'
import { Location } from 'typings/common_defs'
import { toSnakecaseKeys } from 'lib/string'

export async function getCompanyLocations() {
  const data = await graphql(
    `company {
          locations {
            id, name, arrivalInstructions, clockInLat, clockInLong, clockInRadius, 
            address {city, state, street, unit, zip, lat, long, timezone}
          }
        }`,
    'current_company'
  )

  return path(['data', 'data', 'company', 'locations'], data) as Location[]
}

export interface LocationRequest {
  name: string
  street: string
  city: string
  unit?: string
  zip: string
  state: string
  arrivalInstructions?: string
  clockInLat?: number
  clockInLong?: number
  clockInRadius?: number
}

export async function createCompanyLocation(
  companyId: number,
  data: LocationRequest
) {
  const response = await api.post(
    `/company/${companyId}/location`,
    toSnakecaseKeys(data)
  )
  return path(['data', 'id'], response)
}

export async function updateCompanyLocation(
  companyId: number,
  locationId: number,
  data: LocationRequest
) {
  await api.put(
    `/company/${companyId}/location/${locationId}`,
    toSnakecaseKeys(data)
  )
}

export interface W2MinimumLunchLengthOptions {
  companyId: number
  locationId?: number
  startTime: string
  endTime: string
}

export async function getW2MinimumLunchLength(
  options: W2MinimumLunchLengthOptions
): Promise<number> {
  const { companyId, locationId, startTime, endTime } = options

  const data = await api.get(
    `/company/${companyId}/location/${locationId}/minimum_lunch_length`,
    {
      params: {
        start_time: startTime,
        end_time: endTime,
      },
    }
  )

  return path(['data', 'minimum_lunch_length'], data)
}
