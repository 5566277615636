import React, { useEffect, useState } from 'react'
import { differenceInSeconds, isBefore } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { getShiftTimezone } from '../../../lib/shift'
import { Work } from '../../../typings/common_defs'
import { ShiftPayExplanationTag } from './ShiftPayExplanationTag'
import { useShiftDetail } from '../useShiftDetail'
import { Box, Flex, Label, Text, Tooltip } from 'ui'
import { useCompanyInfo } from 'queries/company'
import { BadgeCheckIcon, HelpCircleIcon } from 'lucide-react'
import { toFirstName } from 'lib/string'
import { useAppTheme } from '@workwhile/ui'

interface Props {
  work: Work
}

export const PastWorkInfo = ({ work }: Props): React.ReactElement => {
  const { data: company } = useCompanyInfo()
  const { shift } = useShiftDetail()
  const { colors } = useAppTheme()
  const [start, setStart] = useState<string>('')
  const [end, setEnd] = useState<string>('')
  const [hoursWorked, setHoursWorked] = useState<number>(0)
  const [totalPay, setTotalPay] = useState<number>(0)
  const [morePayThanActuallyWorked, setMorePayThanActuallyWorked] =
    useState<boolean>(false)
  const [workEmployerApprovedAt, setWorkEmployerApprovedAt] = useState<
    string | null
  >(null)

  useEffect(() => {
    if (!shift) return

    if (!work || !work.startedAt || !work.completedAt) return

    const fmt = 'h:mma z'
    const shifttz = getShiftTimezone(shift)

    const startedAtDate = new Date(work.startedAt)
    const completedAtDate = new Date(work.completedAt)
    const shiftEndsAtDate = new Date(shift.endsAt)
    const employerApprovedAtDate = work?.employerApprovedAt
      ? new Date(work.employerApprovedAt)
      : null

    setStart(formatInTimeZone(startedAtDate, shifttz, fmt))
    setEnd(formatInTimeZone(completedAtDate, shifttz, fmt))
    setWorkEmployerApprovedAt(
      employerApprovedAtDate
        ? formatInTimeZone(
            employerApprovedAtDate,
            shifttz,
            'EEE, MMM d h:mma zzz'
          )
        : null
    )

    const hrsWorked = work.timeWorkedSeconds
      ? Math.max(work.timeWorkedSeconds, 0) / 3600
      : 0
    setHoursWorked(hrsWorked)

    const ttlPay = work.earnings ? Math.max(work.earnings, 0) : 0
    setTotalPay(ttlPay)

    const roundedMinutesWorked = Math.round(
      differenceInSeconds(completedAtDate, startedAtDate) / 60
    )
    const actualHoursWorked =
      (roundedMinutesWorked - (shift.lunchLength ? shift.lunchLength : 0)) / 60

    const completedBeforeEndTime = isBefore(completedAtDate, shiftEndsAtDate)
    const receivingMorePayThanActuallyWorked =
      !work.leftEarly &&
      completedBeforeEndTime &&
      actualHoursWorked < hoursWorked

    setMorePayThanActuallyWorked(receivingMorePayThanActuallyWorked)
  }, [work, shift])

  return (
    <Flex
      flexDirection={['column', 'column', 'row']}
      alignItems={['flex-start', 'flex-start', 'center']}
    >
      <Flex flexDirection="row" flexWrap="wrap" mr={3}>
        {`${start} ${start && end ? '-' : ''} ${end} ${
          start || end ? '•' : ''
        } ${hoursWorked.toFixed(2)} hrs`}
        <>
          {' '}
          • Est. ${totalPay.toFixed(2)}
          {'  '}
        </>
      </Flex>
      {morePayThanActuallyWorked ? (
        <Box mr={3}>
          <ShiftPayExplanationTag user={work?.worker} />
        </Box>
      ) : null}
      {work.status === 'bailed' ? (
        <Flex flexDirection="row" alignItems="center" mr={3}>
          <Text color="errors.200" mr={1}>
            No Show - Will Be Blocked
          </Text>
          <Box flex={1}>
            <Tooltip content="If this worker actually did show up, please contact WorkWhile Support">
              <HelpCircleIcon size={14} color={colors.primary} />
            </Tooltip>
          </Box>
        </Flex>
      ) : null}
      {work.supervisorMarkedNoShow ? (
        <Tooltip
          content={`${
            work?.worker?.name ? toFirstName(work?.worker?.name) : 'This worker'
          } was marked a No Show by ${
            company?.name ? `the ${company?.name}` : 'your'
          } Team and will be blocked from working at ${
            company?.name
          } in the future`}
        >
          <Label variant="none" size="small" mr={3}>
            Supervisor Marked No Show
          </Label>
        </Tooltip>
      ) : null}
      {workEmployerApprovedAt ? (
        <Tooltip
          content={`${
            work?.worker?.name ? toFirstName(work?.worker?.name) : 'This worker'
          }'s work was approved by ${
            company?.name ? `the ${company?.name}` : 'your'
          } Team on ${workEmployerApprovedAt}`}
        >
          <BadgeCheckIcon size={14} color={colors.success} />
        </Tooltip>
      ) : null}
    </Flex>
  )
}
