import React from 'react'
import { useTutorialEditorContext } from './useTutorialEditorContext'
import { Heading } from 'ui'

export default function Header() {
  const { isNew } = useTutorialEditorContext()

  return (
    <Heading level={1}>{isNew ? 'Add New Tutorial' : 'Edit Tutorial'}</Heading>
  )
}
