import React, { useMemo, useState } from 'react'
import { ShiftTutorial, TutorialCategory } from 'api/shift'
import {
  Box,
  Card,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  MarkdownProvider,
  MarkdownEditor,
  extractImagesFromMarkdown,
  toast,
  Loading,
} from '@workwhile/ui'
import { getTutorialMetadata } from 'lib/tutorial'
import { PencilLineIcon, Trash2Icon } from 'lucide-react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { SaveOptionsModal } from 'pages/ShiftInstructionEditor/SaveOptionsModal'
import {
  useInvalidateShiftTutorials,
  useRemoveShiftTutorialMutation,
} from 'queries/shift'

interface Props {
  data: Pick<ShiftTutorial, 'category' | 'content'> & { id?: number }
}

const ImagePreview = styled(Box).attrs({
  borderRadius: 'standard',
  width: 56,
  height: 56,
})`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export function TutorialCard(props: Props) {
  const { category, content, id } = props.data
  const shiftId = Number(useParams<{ shiftId: string }>().shiftId)
  const metadata = getTutorialMetadata(category as TutorialCategory)
  const navigate = useNavigate()
  const images = useMemo(() => {
    if (content[0]?.markdown) {
      return extractImagesFromMarkdown(content[0].markdown)
    }
    return []
  }, [content])
  const [showRemovalOptions, setShowRemovalOptions] = useState(false)
  const invalidateTutorials = useInvalidateShiftTutorials()
  const { mutate: removeShiftTutorial, isPending: isRemoving } =
    useRemoveShiftTutorialMutation({
      shiftId,
      onSuccess() {
        invalidateTutorials(shiftId)
      },
      onError(error) {
        toast.error(error.message)
      },
    })

  if (!metadata) {
    return null
  }

  return (
    <>
      <Card
        borderWidth={0}
        height={'100%'}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          navigate(`/shift/${shiftId}/instruction/${id || category}`)
        }
      >
        <Heading level={4} my={0} mb={1}>
          <Flex alignItems={'center'}>
            <Icon icon={metadata.icon} size={24} />
            <Text ml={2}>{metadata?.title}</Text>
          </Flex>
        </Heading>
        {id ? (
          <Flex flexDirection={'column'} flex={1}>
            <Box flex={1}>
              <Flex flexWrap={'wrap'} mt={3}>
                {images.map((image, index) => (
                  <ImagePreview
                    key={index}
                    style={{ backgroundImage: `url(${image.src})` }}
                    mr={2}
                  />
                ))}
              </Flex>
              <Box m={-2} maxHeight={100} overflow={'hidden'} mb={3}>
                <MarkdownProvider>
                  <MarkdownEditor
                    readonly
                    borderless
                    scale={0.8}
                    allowMedia={false}
                    defaultValue={content[0].markdown}
                  />
                </MarkdownProvider>
              </Box>
            </Box>
            <Flex alignItems={'center'} justifyContent={'flex-end'}>
              {isRemoving ? (
                <Loading />
              ) : (
                <>
                  <IconButton color={'primary'} mr={2}>
                    <Icon
                      icon={PencilLineIcon}
                      size={20}
                      color={'currentcolor'}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setShowRemovalOptions(true)
                    }}
                  >
                    <Icon icon={Trash2Icon} size={20} color={'currentcolor'} />
                  </IconButton>
                </>
              )}
            </Flex>
          </Flex>
        ) : (
          <Text
            color={'primary'}
            fontSize={1}
            style={{ textDecoration: 'underline' }}
          >
            Add {metadata?.description}
          </Text>
        )}
      </Card>
      {showRemovalOptions ? (
        <SaveOptionsModal
          open={showRemovalOptions}
          isRemoving
          onOk={(propagate) => {
            if (id) {
              removeShiftTutorial({ tutorialId: id, propagate })
              setShowRemovalOptions(false)
            }
          }}
          onClose={() => setShowRemovalOptions(false)}
        />
      ) : null}
    </>
  )
}
