import React from 'react'
import { Heading, Tabs } from '@workwhile/ui'
import { ContentEditor } from './ContentEditor'
import { QuizEditor } from './QuizEditor'
import { useEditorContext } from './useEditorContext'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'

export function EditorView() {
  const { metadata, quiz, tabValue, setTabValue } = useEditorContext()
  const { value: enableQuiz } = useGate(FeatureGates.EnableNewInstructionsQuiz)

  return (
    <>
      <Heading level={1}>{metadata?.title}</Heading>
      {quiz && enableQuiz ? (
        <Tabs
          value={tabValue}
          onValueChange={setTabValue}
          tabs={[
            {
              value: 'content',
              label: 'Content',
            },
            {
              value: 'quiz',
              label: 'Quiz',
            },
          ]}
        >
          <Tabs.Content value={'content'}>
            <ContentEditor />
          </Tabs.Content>
          <Tabs.Content value={'quiz'}>
            <QuizEditor />
          </Tabs.Content>
        </Tabs>
      ) : (
        <ContentEditor />
      )}
    </>
  )
}
