import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Pluralize,
  Text,
  SwitchRoot,
  SwitchThumb,
  toast,
} from 'ui'
import { useShiftDetail } from '../useShiftDetail'
import {
  ROSTER_USER_STATUS,
  determineIfShiftRestrictedToRosters,
  getNumRosterWorkersAssignedToShift,
  getNumRosterWorkersUnableToAttendShift,
  getRosterWorkersOnShift,
} from 'lib/roster'
import { LucideInfo, LucideUserCheck } from 'lucide-react'
import { AuthGuard, useAuthContext } from 'components/auth'
import { PermissionEnum } from 'api/userPermissions'
import { RemoveRestrictionModal } from 'pages/HomePage/ShiftCard/RemoveRestrictionModal'
import { WorkersSelector } from 'components/WorkersSelector'
import { CompanyWorker, HypotheticalShift } from 'api/worker'
import { RosterSelector } from 'components/RosterSelector'
import { useUpdateShiftRosterMutation } from 'queries/shift/useUpdateShiftRosterMutation'
import { useUpdateRosterWorkersMutation } from 'queries/worker/useUpdateRosterWorkersMutation'
import { track } from 'lib/amplitude'
import useShiftTimeline from 'hooks/useShiftTimeline'
import { AvatarGroup } from '@workwhile/ui'
import { isRSWWorkerAvailabilityEnabled } from 'lib/flags'
import { getHypotheticalShiftsFromExistingShift } from 'lib/shift'
import { useWorkerStats } from 'pages/HomePage/ShiftCard/useWorkerStats'
import { TimelineShift } from 'api/shift'
import { SelectedWorkerStatus } from 'components/WorkersSelector/SelectedWorkerStatus'

export const ShiftDetailAudience = () => {
  const { company } = useAuthContext()
  const { shift } = useShiftDetail()
  const [showEditSelectionModal, setShowEditSelectionModal] = useState(false)
  const { declined } = useWorkerStats(shift as TimelineShift)

  const { mutate: updateShiftRoster, isPending: isUpdatingShiftRosters } =
    useUpdateShiftRosterMutation({
      shiftId: shift?.id || -1,
      onSuccess() {
        setShowEditSelectionModal(false)
        toast.success('List updated successfully')
      },
      onError(err) {
        toast.error(err.message)
      },
    })

  const { mutate: updateRosterWorkers, isPending: isUpdatingRosterWorkers } =
    useUpdateRosterWorkersMutation({
      shiftId: shift?.id,
      rosterId: shift?.rosters?.[0]?.id || -1,
      onSuccess() {
        setShowEditSelectionModal(false)
        toast.success('Workers updated successfully')
      },
      onError(err) {
        toast.error(err.message)
      },
    })

  const { isInProgress, isInPast } = useShiftTimeline(shift)

  if (!shift) {
    return null
  }

  if (!shift.rosters || shift.rosters.length === 0) {
    return null
  }

  const rosterWorkers = getRosterWorkersOnShift(shift)
  const numRosterWorkers = rosterWorkers.length
  const numWorkersAccepted = getNumRosterWorkersAssignedToShift(shift)
  const numWorkersUnableToAttend = getNumRosterWorkersUnableToAttendShift(shift) // TODO(Alex): deprecate once RSW Worker Availability is table in prod
  const numDeclinedText = isRSWWorkerAvailabilityEnabled(company)
    ? declined > 0
      ? `, ${declined} declined`
      : ''
    : numWorkersUnableToAttend > 0
    ? `, ${numWorkersUnableToAttend} unavailable`
    : ''

  const isRosterRestrictionActive = determineIfShiftRestrictedToRosters(shift)
  const isPermHold =
    isRosterRestrictionActive && shift.rostersHoldExpiresAt === null

  const isSpecificWorkerRequest = shift.rosters.some(
    (r) => r.isSpecificWorkerRequest
  )

  /**
   * Given the existing selected workers, and the selected workers chosen by the user
   * Generate the new roster_users array to be sent to the backend
   * see data the PUT endpoint expects: https://github.com/workwhile/backend/blob/2f858909c738448ed9ee0a93e329bce65a01aca0/api/v1/company_roster.py/#L99-L100
   */
  const generateRosterUsersToUpdate = (selectedWorkers: CompanyWorker[]) => {
    const workersToBeActiveOnRoster = selectedWorkers.map((w) => ({
      worker_id: w.id,
      status: ROSTER_USER_STATUS.active,
    }))
    const workersToBeRemovedFromRoster = rosterWorkers
      .filter((w) => !selectedWorkers.some((sw) => sw.id === w.id))
      .map((w) => ({
        worker_id: w.id,
        status: ROSTER_USER_STATUS.removed,
      }))
    return [...workersToBeActiveOnRoster, ...workersToBeRemovedFromRoster]
  }

  const submitSelectedSpecificWorkers = (selectedWorkers: CompanyWorker[]) => {
    track('button_click, shift_detail_rosters_update_specific_workers')
    if (isInProgress || isInPast) {
      toast.error(
        'This shift has already started. You cannot update the audience for this shift. If you require assistance, please contact WorkWhile Support.'
      )
      return
    }
    updateRosterWorkers(generateRosterUsersToUpdate(selectedWorkers))
  }

  const hypotheticalShifts: Array<HypotheticalShift | null> = useMemo(
    () => getHypotheticalShiftsFromExistingShift(shift),
    [shift.location?.id, shift.position?.id, shift.startsAt, shift.endsAt]
  )

  return (
    <Box mb={4}>
      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'center']}
        justifyContent="space-between"
        mb={[3, 3, 0]}
      >
        <Heading level={2} fontWeight={2}>
          Special invites sent to{' '}
          <Pluralize
            count={numRosterWorkers}
            oneText="worker"
            manyText="workers"
          />
        </Heading>
        <AuthGuard.Permission value={PermissionEnum.ManageShifts}>
          <Button
            variant="secondary"
            kind="medium"
            onClick={() => setShowEditSelectionModal(true)}
          >
            {isInProgress || isInPast ? 'View' : 'View / Edit'}
          </Button>
          {isSpecificWorkerRequest ? (
            <WorkersSelector
              open={showEditSelectionModal}
              title="Edit Requested Workers"
              selected={rosterWorkers}
              onClose={() => setShowEditSelectionModal(false)}
              onChange={submitSelectedSpecificWorkers}
              loading={isUpdatingRosterWorkers}
              selectedWorkerStatusComponent={(worker) => {
                return isRSWWorkerAvailabilityEnabled(company) ? (
                  <SelectedWorkerStatus
                    worker={worker}
                    hypotheticalShifts={hypotheticalShifts}
                    existingShift={shift}
                  />
                ) : null
              }}
            />
          ) : (
            <RosterSelector
              open={showEditSelectionModal}
              onClose={() => setShowEditSelectionModal(false)}
              selected={shift.rosters.map((r) => Number(r.id))}
              onChange={updateShiftRoster}
              loading={isUpdatingShiftRosters}
            />
          )}
        </AuthGuard.Permission>
      </Flex>
      <Flex flexDirection="row" alignItems="center" mb={3}>
        <Box mr={3}>
          {isRSWWorkerAvailabilityEnabled(company) ? (
            <AvatarGroup
              max={5}
              users={rosterWorkers.map((worker) => ({
                userId: worker.id.toString(),
                photoUrl: worker?.profilePicUrl,
                name: worker.name,
              }))}
            />
          ) : (
            <LucideUserCheck size={20} />
          )}
        </Box>
        <Text>
          {numWorkersAccepted} accepted
          {numDeclinedText}
        </Text>
      </Flex>
      {isPermHold ? (
        <Flex
          flexDirection={['column', 'column', 'row']}
          alignItems={['flex-start', 'flex-start', 'center']}
          mb={3}
          justifyContent="space-between"
          bg="neutrals.100"
          borderRadius={2}
          p={2}
          border="1px solid"
          borderColor="neutrals.200"
        >
          <Flex alignItems="center" mb={[3, 3, 0]}>
            <LucideInfo size={16} />
            <Text ml={3}>
              If requested workers are unable to attend, let other qualified
              workers accept.
            </Text>
          </Flex>
          <RemoveRestrictionModal
            shift={shift}
            trigger={
              <SwitchRoot checked={!isPermHold}>
                <SwitchThumb>{isPermHold ? 'OFF' : 'ON'}</SwitchThumb>
              </SwitchRoot>
            }
          ></RemoveRestrictionModal>
        </Flex>
      ) : null}
    </Box>
  )
}
