import { useQuery } from '@tanstack/react-query'
import { shiftTutorialKey } from './keys'
import { getShiftTutorial } from 'api/shift'

export function useShiftTutorial(shiftId: number, tutorialId?: number) {
  return useQuery({
    queryKey: shiftTutorialKey(shiftId, tutorialId),
    queryFn: () => {
      if (tutorialId) {
        return getShiftTutorial(shiftId, tutorialId)
      }
    },
    enabled: !!tutorialId,
    gcTime: 0,
  })
}
