import { graphql } from '../graphql'
import { path } from 'ramda'
import { api } from 'api/api'

export enum TutorialCategory {
  General = 'general',
  ArrivalAndParking = 'arrival_and_parking',
  AttireAndGrooming = 'attire_and_grooming',
  ClockIn = 'clock_in',
  ClockOut = 'clock_out',
}

export const AllTutorialCategories = [
  TutorialCategory.General,
  TutorialCategory.ArrivalAndParking,
  TutorialCategory.AttireAndGrooming,
  TutorialCategory.ClockIn,
  TutorialCategory.ClockOut,
]

export interface MarkdownContent {
  markdown: string
}

export interface QuizAnswer {
  label: string
  value: string
}

export interface QuizQuestion {
  key: string
  type: 'radio-group'
  label: string
  values: QuizAnswer[]
  correct_value: string
}

export interface ShiftTutorial {
  id: number
  name: string
  category?: TutorialCategory
  content: MarkdownContent[]
  quiz?: QuizQuestion[]
  companyId?: number
}

export async function getShiftTutorials(shiftId: number) {
  const data = await graphql(
    `company {
          shiftsDetail(ids: [${shiftId}]) {
            tutorials {
              id, name, content, quiz, category, companyId
            }
          }
        }`,
    'shift_tutorials'
  )

  return path(
    ['data', 'data', 'company', 'shiftsDetail', 0, 'tutorials'],
    data
  ).map(
    (
      tutorial: Omit<ShiftTutorial, 'content' | 'quiz'> & {
        content?: string
        quiz: string | null
      }
    ) => ({
      ...tutorial,
      content: tutorial.content ? JSON.parse(tutorial.content) : [],
      quiz: tutorial.quiz ? JSON.parse(tutorial.quiz) : tutorial.quiz,
    })
  ) as ShiftTutorial[]
}

export async function getShiftTutorial(shiftId: number, tutorialId: number) {
  const tutorials = await getShiftTutorials(shiftId)
  return (tutorials || []).find(
    (tutorial) => tutorial.id.toString() === tutorialId.toString()
  )
}

export interface CreateShiftTutorialRequest {
  companyId: number
  shiftId: number
  category: TutorialCategory
  content: MarkdownContent[]
  quiz?: QuizQuestion[]
  propagate: boolean
}

export async function createShiftTutorial(
  request: CreateShiftTutorialRequest
): Promise<number> {
  const { companyId, shiftId, category, content, quiz, propagate } = request
  const data = await api.post(`/company/${companyId}/tutorial`, {
    shift_id: shiftId,
    category,
    content,
    quiz,
  })
  const tutorialId = path(['data', 'id'], data)
  // assign tutorial to shift
  await assignShiftTutorial({ companyId, shiftId, tutorialId, propagate })
  return tutorialId
}

export async function assignShiftTutorial(data: {
  companyId: number
  shiftId: number
  tutorialId: number
  propagate: boolean
}) {
  const { companyId, shiftId, tutorialId, propagate } = data
  return api.post(`/company/${companyId}/tutorial/assign`, {
    shift_id: shiftId,
    tutorial_id: tutorialId,
    propagate,
  })
}

export type EditShiftTutorialRequest = Omit<
  CreateShiftTutorialRequest,
  'category'
> & {
  tutorialId: number
}

export async function editShiftTutorial(
  request: EditShiftTutorialRequest
): Promise<number> {
  const { companyId, shiftId, tutorialId, content, quiz, propagate } = request
  const data = await api.put(`/company/${companyId}/tutorial/${tutorialId}`, {
    shift_id: shiftId,
    content,
    quiz,
    propagate,
  })
  return path(['data', 'id'], data)
}

export type RemoveShiftTutorialRequest = Omit<
  EditShiftTutorialRequest,
  'category' | 'content'
>

export function removeShiftTutorial(request: RemoveShiftTutorialRequest) {
  const { companyId, shiftId, tutorialId, propagate } = request
  return api.post(`/company/${companyId}/tutorial/remove`, {
    shift_id: shiftId,
    tutorial_id: tutorialId,
    propagate,
  })
}
