import React from 'react'
import { TutorialEditorProvider } from './EditorProvider'
import Header from './Header'
import TutorialEditor from './TutorialEditor'
import TutorialError from './TutorialError'
import AddQuiz from './AddQuiz'
import QuizEditor from './QuizEditor'
import QuizError from './QuizError'
import { Routes, Route } from 'react-router'
import Details from './Details'
import FileUploader from './FileUploader'
import { Page } from 'pages/layout'

export default function TutorialEditorRoutes() {
  return (
    <TutorialEditorProvider>
      <Page>
        <Header />
        <Routes>
          <Route path={'/'} element={<Details />} />
          <Route path={'/upload'} element={<FileUploader />} />
          <Route path={'/editor'} element={<TutorialEditor />} />
          <Route path={'/tutorial-error'} element={<TutorialError />} />
          <Route path={'/add-quiz'} element={<AddQuiz />} />
          <Route path={'/quiz-editor'} element={<QuizEditor />} />
          <Route path={'/quiz-error'} element={<QuizError />} />
        </Routes>
      </Page>
    </TutorialEditorProvider>
  )
}
