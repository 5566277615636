import React from 'react'
import { Box, Heading, Pluralize, Flex, Button } from 'ui'
import { useShiftDetail } from './useShiftDetail'
import { getAssignedWork } from 'lib/work'
import { ShiftDetailWorkersAlerts } from './ShiftDetailWorkersAlerts'
import { AuthGuard } from 'components/auth'
import { PermissionEnum } from 'api/userPermissions'
import { isShiftMessagable } from 'lib/shift'
import { ShiftDetailAssignedWorkers } from './assigned_workers/ShiftDetailAssignedWorkers'
import { MessageShiftWorkersModal } from './MessageShiftWorkersModal'

export const ShiftDetailWorkers = () => {
  const { shift } = useShiftDetail()

  if (!shift) return null

  const workList = shift.work || []
  const assignedWork = getAssignedWork(workList)
  const numWorkersAssigned = assignedWork.length

  const canMessageWorkers: boolean =
    isShiftMessagable(shift) && shift.work && shift.work?.length > 0
      ? true
      : false

  return (
    <Box>
      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'center']}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        mb={3}
      >
        <Heading level={2} fontWeight={2}>
          Filled{' '}
          {shift?.workersNeeded && numWorkersAssigned > shift.workersNeeded
            ? shift?.workersNeeded
            : numWorkersAssigned}{' '}
          of{' '}
          <Pluralize
            count={shift.workersNeeded || 0}
            oneText={'worker'}
            manyText={'workers'}
          />
        </Heading>
        <AuthGuard.Permission value={PermissionEnum.MessageWorker}>
          <MessageShiftWorkersModal
            trigger={
              <Button
                kind="medium"
                variant="secondary"
                onClick={() => {
                  // open message modal
                }}
                disabled={!canMessageWorkers}
              >
                Message Workers
              </Button>
            }
          />
        </AuthGuard.Permission>
      </Flex>
      <ShiftDetailWorkersAlerts />
      <ShiftDetailAssignedWorkers />
    </Box>
  )
}
