export const positionTemplateGroups = [
  {
    id: 1,
    name: 'Driving',
    positionTemplates: [
      {
        id: 28,
        name: 'Other',
        requirements: [
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '7',
              category: 'Certificates & Experience',
              name: 'Clean MVR',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '8',
              category: 'Certificates & Experience',
              name: 'Valid drivers license',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '12',
              category: 'General Requirements',
              name: 'Personal vehicle',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '14',
              category: 'General Requirements',
              name: 'No pets or passengers',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '11',
              category: 'General Requirements',
              name: 'Full fuel tank',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '13',
              category: 'General Requirements',
              name: 'Empty car',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '19',
              category: 'General Requirements',
              name: 'SUV - preferred',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '10',
              category: 'Certificates & Experience',
              name: 'Car insurance',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '9',
              category: 'Certificates & Experience',
              name: "Commercial driver's license",
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3295',
              category: 'Certificates & Experience',
              name: 'Routing Apps, Such As Onfleet, Bringg',
              photoUploadNeeded: false,
            },
          },
        ],
      },
      {
        id: 22,
        name: 'Delivery Driver',
        requirements: [
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '7',
              category: 'Certificates & Experience',
              name: 'Clean MVR',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '12',
              category: 'General Requirements',
              name: 'Personal vehicle',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: true,
            requirement: {
              id: '8',
              category: 'Certificates & Experience',
              name: 'Valid drivers license',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '14',
              category: 'General Requirements',
              name: 'No pets or passengers',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '10',
              category: 'Certificates & Experience',
              name: 'Car insurance',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '11',
              category: 'General Requirements',
              name: 'Full fuel tank',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '13',
              category: 'General Requirements',
              name: 'Empty car',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '19',
              category: 'General Requirements',
              name: 'SUV - preferred',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '9',
              category: 'Certificates & Experience',
              name: "Commercial driver's license",
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3295',
              category: 'Certificates & Experience',
              name: 'Routing Apps, Such As Onfleet, Bringg',
              photoUploadNeeded: false,
            },
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Warehouse',
    positionTemplates: [
      {
        id: 29,
        name: 'Other',
        requirements: [
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '24',
              category: 'Attire & Grooming',
              name: 'Safety Vest',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '25',
              category: 'Attire & Grooming',
              name: 'Cold environment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '28',
              category: 'Attire & Grooming',
              name: 'Steel-toed boots',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '30',
              category: 'Certificates & Experience',
              name: 'Computer skills',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '31',
              category: 'Certificates & Experience',
              name: 'HS Diploma or G.E.D.',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '32',
              category: 'Certificates & Experience',
              name: 'Picking',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '33',
              category: 'Certificates & Experience',
              name: 'Shipping',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '34',
              category: 'Certificates & Experience',
              name: 'Receiving',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '35',
              category: 'Certificates & Experience',
              name: 'RF Scanner',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '36',
              category: 'Certificates & Experience',
              name: 'Stand-up reach truck',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '37',
              category: 'Certificates & Experience',
              name: 'Pallet jack',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '38',
              category: 'General Requirements',
              name: 'Full-time',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '40',
              category: 'Attire & Grooming',
              name: 'No jewelry or piercings',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3291',
              category: 'Certificates & Experience',
              name: 'Motorized Pallet Jack',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3292',
              category: 'Certificates & Experience',
              name: 'Wrapping Pallets',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3293',
              category: 'Certificates & Experience',
              name: 'Cherry Picker',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3294',
              category: 'Certificates & Experience',
              name: 'Shift Supervisor',
              photoUploadNeeded: false,
            },
          },
        ],
      },
      {
        id: 30,
        name: 'Warehouse Associate',
        requirements: [
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '24',
              category: 'Attire & Grooming',
              name: 'Safety Vest',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '25',
              category: 'Attire & Grooming',
              name: 'Cold environment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '28',
              category: 'Attire & Grooming',
              name: 'Steel-toed boots',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '30',
              category: 'Certificates & Experience',
              name: 'Computer skills',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '31',
              category: 'Certificates & Experience',
              name: 'HS Diploma or G.E.D.',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '32',
              category: 'Certificates & Experience',
              name: 'Picking',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '33',
              category: 'Certificates & Experience',
              name: 'Shipping',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '34',
              category: 'Certificates & Experience',
              name: 'Receiving',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '35',
              category: 'Certificates & Experience',
              name: 'RF Scanner',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '36',
              category: 'Certificates & Experience',
              name: 'Stand-up reach truck',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '37',
              category: 'Certificates & Experience',
              name: 'Pallet jack',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '38',
              category: 'General Requirements',
              name: 'Full-time',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '40',
              category: 'Attire & Grooming',
              name: 'No jewelry or piercings',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3291',
              category: 'Certificates & Experience',
              name: 'Motorized Pallet Jack',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3292',
              category: 'Certificates & Experience',
              name: 'Wrapping Pallets',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3293',
              category: 'Certificates & Experience',
              name: 'Cherry Picker',
              photoUploadNeeded: false,
            },
          },
        ],
      },
      {
        id: 31,
        name: 'Picker & Packer',
        requirements: [
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '24',
              category: 'Attire & Grooming',
              name: 'Safety Vest',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '25',
              category: 'Attire & Grooming',
              name: 'Cold environment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '28',
              category: 'Attire & Grooming',
              name: 'Steel-toed boots',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '30',
              category: 'Certificates & Experience',
              name: 'Computer skills',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '31',
              category: 'Certificates & Experience',
              name: 'HS Diploma or G.E.D.',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '32',
              category: 'Certificates & Experience',
              name: 'Picking',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '33',
              category: 'Certificates & Experience',
              name: 'Shipping',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '34',
              category: 'Certificates & Experience',
              name: 'Receiving',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '35',
              category: 'Certificates & Experience',
              name: 'RF Scanner',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '36',
              category: 'Certificates & Experience',
              name: 'Stand-up reach truck',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '37',
              category: 'Certificates & Experience',
              name: 'Pallet jack',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '38',
              category: 'General Requirements',
              name: 'Full-time',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '40',
              category: 'Attire & Grooming',
              name: 'No jewelry or piercings',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3291',
              category: 'Certificates & Experience',
              name: 'Motorized Pallet Jack',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3292',
              category: 'Certificates & Experience',
              name: 'Wrapping Pallets',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3293',
              category: 'Certificates & Experience',
              name: 'Cherry Picker',
              photoUploadNeeded: false,
            },
          },
        ],
      },
      {
        id: 32,
        name: 'Forklift Operator',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '35',
              category: 'Certificates & Experience',
              name: 'RF Scanner',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '36',
              category: 'Certificates & Experience',
              name: 'Stand-up reach truck',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '37',
              category: 'Certificates & Experience',
              name: 'Pallet jack',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '38',
              category: 'General Requirements',
              name: 'Full-time',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '40',
              category: 'Attire & Grooming',
              name: 'No jewelry or piercings',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '42',
              category: 'Certificates & Experience',
              name: 'Forklift Certificate',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '24',
              category: 'Attire & Grooming',
              name: 'Safety Vest',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '25',
              category: 'Attire & Grooming',
              name: 'Cold environment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '28',
              category: 'Attire & Grooming',
              name: 'Steel-toed boots',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '30',
              category: 'Certificates & Experience',
              name: 'Computer skills',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '31',
              category: 'Certificates & Experience',
              name: 'HS Diploma or G.E.D.',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '32',
              category: 'Certificates & Experience',
              name: 'Picking',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '33',
              category: 'Certificates & Experience',
              name: 'Shipping',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '34',
              category: 'Certificates & Experience',
              name: 'Receiving',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '41',
              category: 'Certificates & Experience',
              name: 'Forklift Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3291',
              category: 'Certificates & Experience',
              name: 'Motorized Pallet Jack',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3292',
              category: 'Certificates & Experience',
              name: 'Wrapping Pallets',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3293',
              category: 'Certificates & Experience',
              name: 'Cherry Picker',
              photoUploadNeeded: false,
            },
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Event',
    positionTemplates: [
      {
        id: 101,
        name: 'Event Service',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '28',
              category: 'Attire & Grooming',
              name: 'Steel-toed boots',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '43',
              category: 'Attire & Grooming',
              name: 'All black clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '44',
              category: 'Attire & Grooming',
              name: 'Work gloves',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3243',
              category: 'Attire & Grooming',
              name: 'Black Slacks',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3244',
              category: 'Attire & Grooming',
              name: 'Black Nonslip Shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3245',
              category: 'Attire & Grooming',
              name: 'Black Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3246',
              category: 'Attire & Grooming',
              name: 'White Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3252',
              category: 'Certificates & Experience',
              name: 'Customer Service Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3249',
              category: 'General Requirements',
              name: 'Stand, Walk, Bend, Reach',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3250',
              category: 'General Requirements',
              name: 'Loud Enviroment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3251',
              category: 'General Requirements',
              name: 'Exposure to Variable Tempatures',
              photoUploadNeeded: false,
            },
          },
        ],
      },
      {
        id: 102,
        name: 'Guest Service',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '28',
              category: 'Attire & Grooming',
              name: 'Steel-toed boots',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '43',
              category: 'Attire & Grooming',
              name: 'All black clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '44',
              category: 'Attire & Grooming',
              name: 'Work gloves',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3243',
              category: 'Attire & Grooming',
              name: 'Black Slacks',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3244',
              category: 'Attire & Grooming',
              name: 'Black Nonslip Shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3245',
              category: 'Attire & Grooming',
              name: 'Black Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3246',
              category: 'Attire & Grooming',
              name: 'White Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3252',
              category: 'Certificates & Experience',
              name: 'Customer Service Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3249',
              category: 'General Requirements',
              name: 'Stand, Walk, Bend, Reach',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3250',
              category: 'General Requirements',
              name: 'Loud Enviroment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3251',
              category: 'General Requirements',
              name: 'Exposure to Variable Tempatures',
              photoUploadNeeded: false,
            },
          },
        ],
      },
      {
        id: 103,
        name: 'Usher',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '28',
              category: 'Attire & Grooming',
              name: 'Steel-toed boots',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '43',
              category: 'Attire & Grooming',
              name: 'All black clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '44',
              category: 'Attire & Grooming',
              name: 'Work gloves',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3243',
              category: 'Attire & Grooming',
              name: 'Black Slacks',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3244',
              category: 'Attire & Grooming',
              name: 'Black Nonslip Shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3245',
              category: 'Attire & Grooming',
              name: 'Black Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3246',
              category: 'Attire & Grooming',
              name: 'White Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3252',
              category: 'Certificates & Experience',
              name: 'Customer Service Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3249',
              category: 'General Requirements',
              name: 'Stand, Walk, Bend, Reach',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3250',
              category: 'General Requirements',
              name: 'Loud Enviroment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3251',
              category: 'General Requirements',
              name: 'Exposure to Variable Tempatures',
              photoUploadNeeded: false,
            },
          },
        ],
      },
      {
        id: 37,
        name: 'Other',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '28',
              category: 'Attire & Grooming',
              name: 'Steel-toed boots',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '43',
              category: 'Attire & Grooming',
              name: 'All black clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '44',
              category: 'Attire & Grooming',
              name: 'Work gloves',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3243',
              category: 'Attire & Grooming',
              name: 'Black Slacks',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3244',
              category: 'Attire & Grooming',
              name: 'Black Nonslip Shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3245',
              category: 'Attire & Grooming',
              name: 'Black Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3246',
              category: 'Attire & Grooming',
              name: 'White Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3252',
              category: 'Certificates & Experience',
              name: 'Customer Service Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3249',
              category: 'General Requirements',
              name: 'Stand, Walk, Bend, Reach',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3250',
              category: 'General Requirements',
              name: 'Loud Enviroment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3251',
              category: 'General Requirements',
              name: 'Exposure to Variable Tempatures',
              photoUploadNeeded: false,
            },
          },
        ],
      },
      {
        id: 40,
        name: 'Stagehand',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '28',
              category: 'Attire & Grooming',
              name: 'Steel-toed boots',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '43',
              category: 'Attire & Grooming',
              name: 'All black clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '44',
              category: 'Attire & Grooming',
              name: 'Work gloves',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3243',
              category: 'Attire & Grooming',
              name: 'Black Slacks',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3244',
              category: 'Attire & Grooming',
              name: 'Black Nonslip Shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3245',
              category: 'Attire & Grooming',
              name: 'Black Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3246',
              category: 'Attire & Grooming',
              name: 'White Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3252',
              category: 'Certificates & Experience',
              name: 'Customer Service Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3249',
              category: 'General Requirements',
              name: 'Stand, Walk, Bend, Reach',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3250',
              category: 'General Requirements',
              name: 'Loud Enviroment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3251',
              category: 'General Requirements',
              name: 'Exposure to Variable Tempatures',
              photoUploadNeeded: false,
            },
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: 'Food',
    positionTemplates: [
      {
        id: 38,
        name: 'Other',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '25',
              category: 'Attire & Grooming',
              name: 'Cold environment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '40',
              category: 'Attire & Grooming',
              name: 'No jewelry or piercings',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '45',
              category: 'Certificates & Experience',
              name: 'Food Handlers Card',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '46',
              category: 'Attire & Grooming',
              name: 'No long nails',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '47',
              category: 'Attire & Grooming',
              name: 'No hair extensions',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '48',
              category: 'General Requirements',
              name: 'Bilingual: Spanish/English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3297',
              category: 'Certificates & Experience',
              name: 'Food Production',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3298',
              category: 'Certificates & Experience',
              name: 'Bakery',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3299',
              category: 'Certificates & Experience',
              name: 'Dishwashing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3254',
              category: 'Certificates & Experience',
              name: 'Kitchen Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3255',
              category: 'Certificates & Experience',
              name: 'Chop, Mix, Blend Variety Of Foods/Liquids',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3257',
              category: 'Certificates & Experience',
              name: 'Make Mathematical Calculations',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3258',
              category: 'Certificates & Experience',
              name: 'Food Handlers Card - Upload Required',
              photoUploadNeeded: true,
            },
          },
        ],
      },
      {
        id: 41,
        name: 'Food Production',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '25',
              category: 'Attire & Grooming',
              name: 'Cold environment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '40',
              category: 'Attire & Grooming',
              name: 'No jewelry or piercings',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '45',
              category: 'Certificates & Experience',
              name: 'Food Handlers Card',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '48',
              category: 'General Requirements',
              name: 'Bilingual: Spanish/English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '46',
              category: 'Attire & Grooming',
              name: 'No long nails',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: true,
            isWorkwhileRequired: false,
            requirement: {
              id: '47',
              category: 'Attire & Grooming',
              name: 'No hair extensions',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3297',
              category: 'Certificates & Experience',
              name: 'Food Production',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3298',
              category: 'Certificates & Experience',
              name: 'Bakery',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3299',
              category: 'Certificates & Experience',
              name: 'Dishwashing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3254',
              category: 'Certificates & Experience',
              name: 'Kitchen Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3255',
              category: 'Certificates & Experience',
              name: 'Chop, Mix, Blend Variety Of Foods/Liquids',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3258',
              category: 'Certificates & Experience',
              name: 'Food Handlers Card - Upload Required',
              photoUploadNeeded: true,
            },
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: 'General Labor',
    positionTemplates: [
      {
        id: 39,
        name: 'Other',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '25',
              category: 'Attire & Grooming',
              name: 'Cold environment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '28',
              category: 'Attire & Grooming',
              name: 'Steel-toed boots',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
        ],
      },
      {
        id: 42,
        name: 'Associate',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '5',
              category: 'Attire & Grooming',
              name: 'Face Mask',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '6',
              category: 'Attire & Grooming',
              name: 'Closed-toe shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '16',
              category: 'General Requirements',
              name: 'Lift 75lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '17',
              category: 'General Requirements',
              name: 'Lift 100lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '18',
              category: 'General Requirements',
              name: 'Push, pull, squat',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '25',
              category: 'Attire & Grooming',
              name: 'Cold environment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '27',
              category: 'Attire & Grooming',
              name: 'Warm clothing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '28',
              category: 'Attire & Grooming',
              name: 'Steel-toed boots',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '29',
              category: 'Attire & Grooming',
              name: 'Long pants',
              photoUploadNeeded: false,
            },
          },
        ],
      },
    ],
  },
  {
    id: 6,
    name: 'Hospitality',
    positionTemplates: [
      {
        id: 43,
        name: 'Concessions',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3649',
              category: 'Certificates & Experience',
              name: 'Tennessee Alcoholic Beverage Commission Certification',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '26',
              category: 'Attire & Grooming',
              name: 'Black pants',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3258',
              category: 'Certificates & Experience',
              name: 'Food Handlers Card - Upload Required',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3259',
              category: 'Certificates & Experience',
              name: 'RBS (Responsible  Beverage Certification)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3260',
              category: 'Certificates & Experience',
              name: 'TIPS (Training and Interventions Procedures For Alcohol Servers)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3261',
              category: 'Certificates & Experience',
              name: 'TAM (Techniques of Alcohol Management)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3262',
              category: 'Certificates & Experience',
              name: 'MAST (Mandatory Alcohol Server Training)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3263',
              category: 'Certificates & Experience',
              name: 'Class 12 Mixologist Permit',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3264',
              category: 'Certificates & Experience',
              name: 'Class 13 Mixologist Permit',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3265',
              category: 'Certificates & Experience',
              name: 'BASSET (Beverage Alcohol Sellers and Servers Education and Training)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3266',
              category: 'Certificates & Experience',
              name: 'ATAP (Alcohol Training and Awareness Program)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3267',
              category: 'Certificates & Experience',
              name: 'BASIC Title 4 Training',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3268',
              category: 'Certificates & Experience',
              name: 'TABC Certification (Texas Alcoholic Beverage Commission)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3243',
              category: 'Attire & Grooming',
              name: 'Black Slacks',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3244',
              category: 'Attire & Grooming',
              name: 'Black Nonslip Shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3245',
              category: 'Attire & Grooming',
              name: 'Black Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3246',
              category: 'Attire & Grooming',
              name: 'White Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3252',
              category: 'Certificates & Experience',
              name: 'Customer Service Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3249',
              category: 'General Requirements',
              name: 'Stand, Walk, Bend, Reach',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3253',
              category: 'Certificates & Experience',
              name: 'Basic POS Knowledge',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3250',
              category: 'General Requirements',
              name: 'Loud Enviroment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3251',
              category: 'General Requirements',
              name: 'Exposure to Variable Tempatures',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3402',
              category: 'Attire & Grooming',
              name: 'Black Slacks - Photo Upload Required',
              photoUploadNeeded: true,
            },
          },
        ],
      },
      {
        id: 44,
        name: 'Steward',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3258',
              category: 'Certificates & Experience',
              name: 'Food Handlers Card - Upload Required',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3243',
              category: 'Attire & Grooming',
              name: 'Black Slacks',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3244',
              category: 'Attire & Grooming',
              name: 'Black Nonslip Shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3245',
              category: 'Attire & Grooming',
              name: 'Black Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3246',
              category: 'Attire & Grooming',
              name: 'White Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3254',
              category: 'Certificates & Experience',
              name: 'Kitchen Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3249',
              category: 'General Requirements',
              name: 'Stand, Walk, Bend, Reach',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3250',
              category: 'General Requirements',
              name: 'Loud Enviroment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3251',
              category: 'General Requirements',
              name: 'Exposure to Variable Tempatures',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3402',
              category: 'Attire & Grooming',
              name: 'Black Slacks - Photo Upload Required',
              photoUploadNeeded: true,
            },
          },
        ],
      },
      {
        id: 45,
        name: 'Server',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3649',
              category: 'Certificates & Experience',
              name: 'Tennessee Alcoholic Beverage Commission Certification',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3258',
              category: 'Certificates & Experience',
              name: 'Food Handlers Card - Upload Required',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3259',
              category: 'Certificates & Experience',
              name: 'RBS (Responsible  Beverage Certification)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3260',
              category: 'Certificates & Experience',
              name: 'TIPS (Training and Interventions Procedures For Alcohol Servers)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3261',
              category: 'Certificates & Experience',
              name: 'TAM (Techniques of Alcohol Management)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3262',
              category: 'Certificates & Experience',
              name: 'MAST (Mandatory Alcohol Server Training)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3263',
              category: 'Certificates & Experience',
              name: 'Class 12 Mixologist Permit',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3264',
              category: 'Certificates & Experience',
              name: 'Class 13 Mixologist Permit',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3265',
              category: 'Certificates & Experience',
              name: 'BASSET (Beverage Alcohol Sellers and Servers Education and Training)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3266',
              category: 'Certificates & Experience',
              name: 'ATAP (Alcohol Training and Awareness Program)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3267',
              category: 'Certificates & Experience',
              name: 'BASIC Title 4 Training',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3268',
              category: 'Certificates & Experience',
              name: 'TABC Certification (Texas Alcoholic Beverage Commission)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3247',
              category: 'Attire & Grooming',
              name: 'Black Long Sleeve Collared Shirt',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3248',
              category: 'Attire & Grooming',
              name: 'White Long Sleeve Collared Shirt',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3243',
              category: 'Attire & Grooming',
              name: 'Black Slacks',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3244',
              category: 'Attire & Grooming',
              name: 'Black Nonslip Shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3249',
              category: 'General Requirements',
              name: 'Stand, Walk, Bend, Reach',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3250',
              category: 'General Requirements',
              name: 'Loud Enviroment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3251',
              category: 'General Requirements',
              name: 'Exposure to Variable Tempatures',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3256',
              category: 'Certificates & Experience',
              name: 'Experience in a Fast Paced Resturaunt or Club',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3253',
              category: 'Certificates & Experience',
              name: 'Basic POS Knowledge',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3402',
              category: 'Attire & Grooming',
              name: 'Black Slacks - Photo Upload Required',
              photoUploadNeeded: true,
            },
          },
        ],
      },
      {
        id: 46,
        name: 'Cook',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3258',
              category: 'Certificates & Experience',
              name: 'Food Handlers Card - Upload Required',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3243',
              category: 'Attire & Grooming',
              name: 'Black Slacks',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3244',
              category: 'Attire & Grooming',
              name: 'Black Nonslip Shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3245',
              category: 'Attire & Grooming',
              name: 'Black Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3246',
              category: 'Attire & Grooming',
              name: 'White Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3254',
              category: 'Certificates & Experience',
              name: 'Kitchen Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3249',
              category: 'General Requirements',
              name: 'Stand, Walk, Bend, Reach',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3250',
              category: 'General Requirements',
              name: 'Loud Enviroment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3251',
              category: 'General Requirements',
              name: 'Exposure to Variable Tempatures',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3255',
              category: 'Certificates & Experience',
              name: 'Chop, Mix, Blend Variety Of Foods/Liquids',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3257',
              category: 'Certificates & Experience',
              name: 'Make Mathematical Calculations',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3299',
              category: 'Certificates & Experience',
              name: 'Dishwashing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3402',
              category: 'Attire & Grooming',
              name: 'Black Slacks - Photo Upload Required',
              photoUploadNeeded: true,
            },
          },
        ],
      },
      {
        id: 47,
        name: 'Other',
        requirements: [
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3649',
              category: 'Certificates & Experience',
              name: 'Tennessee Alcoholic Beverage Commission Certification',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3243',
              category: 'Attire & Grooming',
              name: 'Black Slacks',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3244',
              category: 'Attire & Grooming',
              name: 'Black Nonslip Shoes',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3245',
              category: 'Attire & Grooming',
              name: 'Black Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3246',
              category: 'Attire & Grooming',
              name: 'White Shirt (No Logos)',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3247',
              category: 'Attire & Grooming',
              name: 'Black Long Sleeve Collared Shirt',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3248',
              category: 'Attire & Grooming',
              name: 'White Long Sleeve Collared Shirt',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3249',
              category: 'General Requirements',
              name: 'Stand, Walk, Bend, Reach',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3250',
              category: 'General Requirements',
              name: 'Loud Enviroment',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3251',
              category: 'General Requirements',
              name: 'Exposure to Variable Tempatures',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3252',
              category: 'Certificates & Experience',
              name: 'Customer Service Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3253',
              category: 'Certificates & Experience',
              name: 'Basic POS Knowledge',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3254',
              category: 'Certificates & Experience',
              name: 'Kitchen Experience',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3255',
              category: 'Certificates & Experience',
              name: 'Chop, Mix, Blend Variety Of Foods/Liquids',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3256',
              category: 'Certificates & Experience',
              name: 'Experience in a Fast Paced Resturaunt or Club',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3257',
              category: 'Certificates & Experience',
              name: 'Make Mathematical Calculations',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3258',
              category: 'Certificates & Experience',
              name: 'Food Handlers Card - Upload Required',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3259',
              category: 'Certificates & Experience',
              name: 'RBS (Responsible  Beverage Certification)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3260',
              category: 'Certificates & Experience',
              name: 'TIPS (Training and Interventions Procedures For Alcohol Servers)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3261',
              category: 'Certificates & Experience',
              name: 'TAM (Techniques of Alcohol Management)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3262',
              category: 'Certificates & Experience',
              name: 'MAST (Mandatory Alcohol Server Training)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3263',
              category: 'Certificates & Experience',
              name: 'Class 12 Mixologist Permit',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3264',
              category: 'Certificates & Experience',
              name: 'Class 13 Mixologist Permit',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3265',
              category: 'Certificates & Experience',
              name: 'BASSET (Beverage Alcohol Sellers and Servers Education and Training)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3266',
              category: 'Certificates & Experience',
              name: 'ATAP (Alcohol Training and Awareness Program)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3267',
              category: 'Certificates & Experience',
              name: 'BASIC Title 4 Training',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3268',
              category: 'Certificates & Experience',
              name: 'TABC Certification (Texas Alcoholic Beverage Commission)',
              photoUploadNeeded: true,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '15',
              category: 'General Requirements',
              name: 'Lift 50lbs',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '21',
              category: 'General Requirements',
              name: 'Read and write in English',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '22',
              category: 'General Requirements',
              name: 'Stand for long periods',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3299',
              category: 'Certificates & Experience',
              name: 'Dishwashing',
              photoUploadNeeded: false,
            },
          },
          {
            isPreSelected: false,
            isWorkwhileRequired: false,
            requirement: {
              id: '3402',
              category: 'Attire & Grooming',
              name: 'Black Slacks - Photo Upload Required',
              photoUploadNeeded: true,
            },
          },
        ],
      },
    ],
  },
]
