import React from 'react'
import { Box, Flex, Heading, Tooltip } from 'ui'
import { useAuthContext } from 'components/auth'

export function CompanyHeader() {
  const { company } = useAuthContext()

  if (!company) {
    return null
  }

  return (
    <Flex flexDirection={'row'} alignItems={'center'} p={4} pl={30} pb={2}>
      <Box mr={3} flexShrink={0} width={'4rem'} height={'4rem'}>
        {company.logoUrl ? (
          <img
            src={company.logoUrl}
            alt={company.name}
            width={'100%'}
            height={'100%'}
          />
        ) : (
          <Tooltip content={'Please contact us to upload your company logo'}>
            <Flex
              width={'4rem'}
              height={'4rem'}
              alignItems={'center'}
              justifyContent={'center'}
              backgroundColor={'neutrals.200'}
              borderRadius={'50%'}
            />
          </Tooltip>
        )}
      </Box>
      <Box width={160} flexShrink={0}>
        <Heading level={4} className={'company-name'} maxLines={3}>
          {company.name}
        </Heading>
      </Box>
    </Flex>
  )
}
