import { useContext } from "react";
import { EditorContext } from "./EditorProvider";

export function useTutorialEditorContext() {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error("useTutorialEditorContext must be used within a TutorialEditorProvider");
  }
  return context;
}
