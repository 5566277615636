import React, { useEffect } from 'react'
import { Box, Flex, Loading, Text, useAppTheme } from '@workwhile/ui'
import { User } from 'typings/common_defs'
import { useWorkerAvailability } from 'queries/worker'
import { HypotheticalShift } from 'api/worker'
import { Tooltip } from '@workwhile/ui'
import { WorkerUnavailableReasons } from './WorkerUnavailableReasons'
import { LucideInfo } from 'lucide-react'
import {
  DeclinedWorkStatuses,
  ShiftEditData,
  TimelineShift,
  WorkStatusEnum,
} from 'api/shift'
import useShiftTimeline from 'hooks/useShiftTimeline'
import { track } from 'lib/amplitude'

/**
 * Possible statuses:
 * - Accepted
 * - Available
 * - Available X of Y days
 * - Awaiting Response
 * - Unavailable
 */

interface Props {
  worker: User
  hypotheticalShifts: Array<HypotheticalShift | null> // info on shifts to determine worker's availability
  existingShift?: TimelineShift | ShiftEditData // shift that has already been created. If it exists, we can determine if worker is already assigned to the shift
}

export const SelectedWorkerStatus = ({
  worker,
  hypotheticalShifts,
  existingShift,
}: Props) => {
  const { colors } = useAppTheme()
  const { data: workerAvailabilities, isLoading } = useWorkerAvailability({
    workerIds: [worker.id],
    hypotheticalShifts: existingShift ? [] : hypotheticalShifts, // only pass hypothetical shifts if there is not an existing shift
    shiftIds: existingShift ? [existingShift.id] : [],
  })
  const { isInProgress, isInPast } = useShiftTimeline(existingShift || null)

  useEffect(() => {
    // componentDidMount
    track('impression, selected_worker_status', {
      workerId: worker.id,
    })
  }, [])

  const availabilitiesForWorker = workerAvailabilities?.filter(
    (availability) => Number(availability.workerId) === Number(worker.id)
  )

  useEffect(() => {
    // trigger amplitude call after availabilitiesForWorker are fetched
    if (availabilitiesForWorker && availabilitiesForWorker.length > 0) {
      track('impression, availabilities_for_worker', {
        workerId: worker.id,
        isAvailableAllDays: availabilitiesForWorker?.every(
          (availability) => availability.available
        ),
      })
    }
  }, [availabilitiesForWorker])

  if (isLoading) {
    return <Loading type="button" />
  }

  // if the worker is already scheduled for the shift, return 'Accepted'
  if (existingShift?.work?.some((work) => work.worker?.id === worker.id)) {
    return <Text fontSize={1}>Accepted</Text>
  }

  // if the worker declined the shift
  if (
    existingShift?.unableToAttendWork?.some(
      (work) =>
        Number(work.worker?.id) === Number(worker.id) &&
        DeclinedWorkStatuses.includes(work.status as WorkStatusEnum)
    )
  ) {
    return <Text fontSize={1}>Declined</Text>
  }

  // if the shift has already started
  if (existingShift && (isInProgress || isInPast)) {
    // otherwise don't return anything
    return <Text fontSize={1}></Text>
  }

  if (
    !availabilitiesForWorker ||
    availabilitiesForWorker === undefined ||
    availabilitiesForWorker.length < 0
  ) {
    return null
  }

  // if the worker is available for all shifts
  if (
    availabilitiesForWorker?.every((availability) => availability.available)
  ) {
    // if the shift has already been created, return 'Awaiting Response'
    if (existingShift) {
      return <Text fontSize={1}>Awaiting Response</Text>
    }
    // otherwise return "Available"
    return <Text fontSize={1}>Available</Text>
  }

  const renderAvailabilityStatus = (
    availableCount: number,
    totalDays: number
  ) => {
    const statusText =
      availableCount === 0
        ? `Unavailable${totalDays > 1 ? ' all days' : ''}`
        : `Available ${availableCount} of ${totalDays} days`
    const iconSize = availableCount === 0 ? 14 : 10

    return (
      <Box>
        <Tooltip
          content={
            <WorkerUnavailableReasons
              worker={worker}
              availabilitiesForWorker={availabilitiesForWorker}
            />
          }
          variant="neutral"
        >
          <Flex flexDirection="row" flex={1} alignItems="center">
            <Text
              fontSize={1}
              mr={1}
              color="info"
              style={{ textDecoration: 'underline' }}
            >
              {statusText}
            </Text>
            <LucideInfo size={iconSize} color={colors.info} />
          </Flex>
        </Tooltip>
      </Box>
    )
  }

  const availableCount = availabilitiesForWorker.filter(
    (availability) => availability.available
  ).length
  const totalDays = availabilitiesForWorker.length
  return renderAvailabilityStatus(availableCount, totalDays)
}
