import React from 'react'
import styled from 'styled-components'
import WYSIWIGEditor from '../TutorialWYSIWIGEditor'
import { useTutorialEditorContext } from './useTutorialEditorContext'
import { useErrorBoundary } from 'use-error-boundary'
import TutorialError from './TutorialError'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Heading, Message, Paragraph } from 'ui'

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

export default function TutorialEditor() {
  const { tutorialData, saveTutorial, generateTutorial, isSubmitting } =
    useTutorialEditorContext()

  const { ErrorBoundary, didCatch } = useErrorBoundary()
  const formData = {
    pages: tutorialData,
  }
  const methods = useForm({
    defaultValues: formData,
  })
  const {
    formState: { errors },
    handleSubmit,
  } = methods
  const onSubmit = handleSubmit((data) => {
    if (data.pages) {
      saveTutorial(data.pages)
    }
  })

  if (!tutorialData) {
    return null
  }

  if (didCatch) {
    return <TutorialError />
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <ErrorBoundary>
          <Container>
            <div style={{ flex: 0.5, paddingRight: '3rem' }}>
              <Heading level={2}>Review & Edit Tutorial</Heading>
              <Message variant={'info'}>
                This Tutorial was generated by Artifical Intelligence and may
                contain information that is incorrect.
              </Message>
              <Paragraph py={2}>
                <ol style={{ paddingInlineStart: 0 }}>
                  <li>1. Please review the Tutorial carefully.</li>
                  <br />
                  <li>2. To edit, simply click the screen.</li>
                  <br />
                  <li>
                    3. When you're done, please click "Save Tutorial" below.
                  </li>
                </ol>
              </Paragraph>
              <Button type={'submit'} loading={isSubmitting} width={200}>
                Save Tutorial
              </Button>
              <hr style={{ margin: '2rem 0' }} />
              <div style={{ margin: '2rem 0' }}>
                <Paragraph>Not happy? Ask AI to try again.</Paragraph>
              </div>
              <Button
                width={200}
                variant={'secondary'}
                onClick={generateTutorial}
              >
                Regenerate
              </Button>
            </div>
            <div style={{ flex: 1 }}>
              <WYSIWIGEditor data={tutorialData} />
            </div>
          </Container>
        </ErrorBoundary>
      </form>
    </FormProvider>
  )
}
