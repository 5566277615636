import React from 'react'
import { useEditorContext } from './useEditorContext'
import { Box, Button, Flex, Heading, Icon } from '@workwhile/ui'
import { PlusIcon } from 'lucide-react'
import { QuizQuestionCard } from './QuizQuestionCard'
import { QuizPreview } from './QuizPreview'
import { SaveBox } from './SaveBox'

export function QuizEditor() {
  const { quiz, addQuizQuestion } = useEditorContext()

  return (
    <Box>
      <Heading level={3}>Edit Your Quiz</Heading>
      <Flex>
        <Box flex={1} mr={[0, 0, 0, 4, 7]}>
          {(quiz || []).map((question) => (
            <QuizQuestionCard question={question} key={question.key} />
          ))}
          <Button variant={'text'} onClick={addQuizQuestion}>
            <Icon icon={PlusIcon} size={16} />
            &nbsp;Add Question
          </Button>
          <SaveBox />
        </Box>
        <Box display={['none', 'none', 'none', 'block']}>
          <Box position={'sticky'} top={60}>
            <QuizPreview />
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}
