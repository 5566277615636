import { PolicyWindow, User, Work, WorkShift } from 'typings/common_defs'
import { getLocationTimezone, getUTCDateTime, toMinutes } from './time'
import { getRosterWorkersOnShift } from './roster'
import { FINISHED_WORK_STATUS } from './legacy_util'
import { TimelineShift } from 'api/shift'
import { addHours, subHours, isWithinInterval, addDays } from 'date-fns'
import { getAssignedWork } from './work'
import { PartialShiftData } from 'pages/ShiftEditorPage/schemas'
import { HypotheticalShift } from 'api/worker'

export const getShiftTimezone = (
  shift: WorkShift | TimelineShift | null | undefined
): string => getLocationTimezone(shift?.location)

export const DEFAULT_CANCELLATION_POLICY_WINDOW: PolicyWindow[] = [
  { cutoffTimeHours: 24, chargePercentage: 100 },
]

/**
 * Returns true if duplicate shift action should be shown to user
 * @param {WorkShift | TimelineShift} shift
 * @returns boolean
 */
export const shouldShowDuplicateShiftAction = (
  shift: WorkShift | TimelineShift
): boolean => {
  return !shift?.position?.hidden
}

/**
 * Given a shift, sort assigned work by priority
 * - Clocked in
 * - Clocked out
 * - Marked no show
 * - On a Roster associated with the shift
 * - Everything else
 * @param {WorkShift | TimelineShift} shift
 * @returns {Array<Work>}
 */
export const sortWorkByPriority = (
  shift: WorkShift | TimelineShift
): Array<Work> => {
  if (!shift || !shift.work) {
    return []
  }

  const rosterWorkers: Array<User> = getRosterWorkersOnShift(shift)
  const rosterWorkerIds: Set<number> = new Set(
    rosterWorkers.map((worker) => worker.id)
  )

  const clockedIn: Array<Work> = []
  const clockedOut: Array<Work> = []
  const markedNoShow: Array<Work> = []
  const onRoster: Array<Work> = []
  const everythingElse: Array<Work> = []

  for (const work of shift.work) {
    if (work.status === 'started' && !work.supervisorMarkedNoShow) {
      clockedIn.push(work)
    } else if (
      work.status &&
      FINISHED_WORK_STATUS.includes(work.status) &&
      !work.supervisorMarkedNoShow
    ) {
      clockedOut.push(work)
    } else if (work.supervisorMarkedNoShow) {
      markedNoShow.push(work)
    } else if (work.worker && rosterWorkerIds.has(work.worker.id)) {
      onRoster.push(work)
    } else {
      everythingElse.push(work)
    }
  }

  // Sort each array by the worker's name alphabetically
  const sortByName = (a: Work, b: Work) => {
    const nameA = a.worker?.name || ''
    const nameB = b.worker?.name || ''
    return nameA.localeCompare(nameB)
  }

  clockedIn.sort(sortByName)
  clockedOut.sort(sortByName)
  markedNoShow.sort(sortByName)
  onRoster.sort(sortByName)
  everythingElse.sort(sortByName)

  return [
    ...clockedIn,
    ...clockedOut,
    ...markedNoShow,
    ...onRoster,
    ...everythingElse,
  ]
}

/**
 * Given a worker and a shift, return true if the worker is unable to attend the shift
 * @param {User} worker
 * @param {WorkShift | TimelineShift} shift
 * @returns {boolean}
 */
export const determineIfWorkerUnableToAttendShift = (
  worker: User,
  shift: WorkShift | TimelineShift
): boolean => {
  const unableToAttendWork = shift.unableToAttendWork || []

  for (const work of unableToAttendWork) {
    if (work.worker && work.worker.id === worker.id) {
      return true
    }
  }
  return false
}

/**
 * Given a worker and a shift, return true if the worker is assigned to the shift
 * @param {User} worker
 * @param {WorkShift | TimelineShift} shift
 * @returns {boolean}
 * */
export const determineIfWorkerAssignedToShift = (
  worker: User,
  shift: WorkShift | TimelineShift
): boolean => {
  const work = shift.work || []

  for (const w of work) {
    if (w.worker && w.worker.id === worker.id) {
      return true
    }
  }
  return false
}

// number of hours before shift start when workers can be messaged
export const NUM_HOURS_MESSAGING_ALLOWED_BEFORE = 36
// number of hours after shift end when workers can be messaged
export const NUM_HOURS_MESSAGING_ALLOWED_AFTER = 2

/**
 * Determines if workers on a shift are messagable
 * @param {WorkShift | TimelineShift | null} shift
 * @returns {boolean} true if shift is messagable, false otherwise
 */
export const isShiftMessagable = (
  shift: WorkShift | TimelineShift | null
): boolean => {
  const { startsAt, endsAt } = shift || {}

  if (!startsAt || !endsAt) {
    return false
  }

  const now = new Date()
  const startBoundary = subHours(
    new Date(startsAt),
    NUM_HOURS_MESSAGING_ALLOWED_BEFORE
  )
  const endBoundary = addHours(
    new Date(endsAt),
    NUM_HOURS_MESSAGING_ALLOWED_AFTER
  )

  return isWithinInterval(now, { start: startBoundary, end: endBoundary })
}

/**
 * Determines if we should show clock in/out codes for a shift
 * - shift requires clock in/out codes
 * - not all workers on the shift have finished work
 * @param {WorkShift | TimelineShift | null} shift
 * @returns {boolean} true if clock in/out codes should be shown, false otherwise
 */
export const shouldShowClockInOutCodes = (
  shift: WorkShift | TimelineShift | null
): boolean => {
  if (!shift) return false

  const workList = shift.work || []
  const assignedWork = getAssignedWork(workList)

  // if all the workers are in a finished status, show hide the clock in/out codes
  if (
    assignedWork.every(
      (work) =>
        work.status &&
        (FINISHED_WORK_STATUS.includes(work.status) || work.status === 'bailed')
    )
  ) {
    return false
  }

  // if the shift uses SecureCheckIn/Out (i.e. QBar) do not show clock in/out codes
  if (shift.requiresQrClockInCode || shift.requiresQrClockOutCode) {
    return false
  }

  // if the shift does not require clock in/out codes, do not show them
  if (!shift.requiresClockInCode && !shift.requiresClockOutCode) {
    return false
  }

  return true
}

/**
 * Returns the number of hours after a shift when work will be auto-approved
 * @param {WorkShift | TimelineShift | null} shift
 * @returns {number} number of hours after a shift when work will be auto-approved
 */
export const getAutoApproveHours = (
  shift: WorkShift | TimelineShift | null
): number => {
  if (!shift) return 24

  return shift.shiftEndPaymentDelayHours || 24
}

/**
 * Given a shift, returns the Date when the work will be auto-approved
 */
export const getAutoApproveDate = (shift: WorkShift | TimelineShift): Date => {
  const { endsAt } = shift

  const shiftEndPaymentDelayHours = getAutoApproveHours(shift)

  if (!endsAt) {
    return addHours(new Date(), shiftEndPaymentDelayHours)
  }

  return addHours(new Date(endsAt), shiftEndPaymentDelayHours)
}

/**
 * Given ShiftEditor data, returns a HypotheticalShift object for calculating worker availability
 * @param {PartialShiftData} shiftData
 * @returns {Array<HypotheticalShift | null>}
 */
export const getHypotheticalShiftsFromShiftEditorData = (
  shiftData?: PartialShiftData | null
): Array<HypotheticalShift | null> => {
  if (!shiftData) return []

  return (
    shiftData?.schedule?.selectedDays?.map((date) => {
      if (!shiftData.schedule) {
        return null
      }
      const timezone = shiftData.schedule.timezone
      const startMinutes = toMinutes(shiftData.schedule.startTime.value)
      const endMinutes = toMinutes(shiftData.schedule.endTime.value)
      const endDate = endMinutes < startMinutes ? addDays(date, 1) : date

      if (!shiftData.details?.positionId || !shiftData.details?.locationId) {
        return null
      }
      return {
        positionId: shiftData.details.positionId,
        locationId: shiftData.details.locationId,
        start: getUTCDateTime(
          date,
          shiftData.schedule.startTime.value,
          timezone
        ).toISOString(),
        end: getUTCDateTime(
          endDate,
          shiftData.schedule.endTime.value,
          timezone
        ).toISOString(),
      }
    }) ?? []
  )
}

/**
 * Given an existing shift, returns a HypotheticalShift object for calculating worker availability
 * @param {WorkShift | TimelineShift} shift
 * @returns {Array<HypotheticalShift | null>}
 */
export const getHypotheticalShiftsFromExistingShift = (
  shift: WorkShift | TimelineShift
): Array<HypotheticalShift | null> => {
  if (!shift) return []

  if (
    !shift.startsAt ||
    !shift.endsAt ||
    !shift.position?.id ||
    !shift.location?.id
  ) {
    return []
  }

  return [
    {
      positionId: shift.position.id,
      locationId: shift.location.id,
      start: shift.startsAt,
      end: shift.endsAt,
    },
  ]
}
