import React, { useEffect } from 'react'
import { Flex, Box, Button, Heading, Message, Text } from 'ui'
import { track } from 'lib/amplitude'
import { Footer } from '../../Footer'
import { useShiftEditor } from '../../useShiftEditor'
import { StepEnum } from '../../ShiftEditorProvider'
import { useShiftSummary } from './useShiftSummary'
import { ReviewCallouts } from './ReviewCallouts'
import { useCompanyPosition } from 'hooks/useCompanyPosition'
import { VscMegaphone } from 'react-icons/vsc'
import { SummaryBox } from './SummaryBox'
import { Preview } from '../../Preview'
import { TryoutLabel } from 'components/tryouts/TryoutLabel'

export function ConfirmPage() {
  useEffect(() => {
    track('impression, ShiftEditor_Review_page')
  }, [])

  const { goTo, editorError, submitShiftData, shiftData, isTryout } =
    useShiftEditor()
  const summary = useShiftSummary()
  const position = useCompanyPosition(shiftData?.details?.positionId)

  const lineItems = [
    {
      title: 'Position',
      value: summary.position,
      cta: () => goTo(StepEnum.Details),
    },
    {
      title: 'Location',
      value: summary.location,
      cta: () => goTo(StepEnum.Details),
    },
    {
      title: 'Time',
      value: summary.time,
      cta: () => goTo(StepEnum.Schedule),
    },
    {
      title: 'Unpaid Break',
      value: summary.unpaidBreak,
      cta: () => goTo(StepEnum.Schedule),
    },
    {
      title: 'Dates',
      value:
        summary.formattedDates.length > 1 ? (
          <>
            <Box flex={1} mt={2}>
              Dates
              <Box px={3} py={3}>
                {summary.formattedDates.map((date, idx) => (
                  <Text key={idx}>{date}</Text>
                ))}
              </Box>
            </Box>
            <Box mr={4} fontWeight={2}>
              {summary.dates.length} days
            </Box>
            <Button variant={'text'} onClick={() => goTo(StepEnum.Schedule)}>
              Edit
            </Button>
          </>
        ) : (
          summary.formattedDates[0]
        ),
      cta: () => goTo(StepEnum.Schedule),
    },
    {
      title: 'Supervisors',
      value: summary.supervisors,
      cta: () => goTo(StepEnum.Staff),
    },
    {
      title: 'Workers',
      value: summary.workers,
      cta: () => goTo(StepEnum.Staff),
    },
    {
      title: 'Pay',
      value: summary.pay,
      cta: () => goTo(StepEnum.Payment),
    },
    {
      title: 'Bonus',
      value: summary.bonus,
      cta: () => goTo(StepEnum.Payment),
    },
  ]

  const shiftLeadLineItems = [
    {
      title: 'Shift Leads',
      value: summary.shiftLeads,
      cta: () => goTo(StepEnum.Staff),
    },
    {
      title: 'Time',
      value: summary.shiftLeadTime,
      cta: () => goTo(StepEnum.Staff),
    },
    {
      title: 'Pay',
      value: summary.shiftLeadPay,
      cta: () => goTo(StepEnum.Payment),
    },
  ]

  return (
    <Flex flexWrap={'wrap'}>
      <Box width={[1, 1, 1, '50rem', '65rem']} pr={[0, 2, 5]}>
        <form
          onSubmit={(e) => {
            e.stopPropagation()
            e.preventDefault()
            submitShiftData()
          }}
        >
          <Heading level={[2, 1]} my={[4, 5]}>
            Review & Confirm Your {isTryout ? 'Tryout' : 'Shift'}
          </Heading>
          {isTryout ? (
            <Box mb={3}>
              <TryoutLabel />
            </Box>
          ) : null}
          <Box>
            <Heading level={3}>
              {isTryout ? 'Tryout Summary' : 'Shift Summary'}
            </Heading>
            <Text>Please make sure all the information is correct.</Text>
            {isTryout ? (
              <Message
                mt={3}
                variant={'fixed'}
                py={3}
                icon={<VscMegaphone fontSize={18} />}
              >
                <Text fontWeight={2} mb={2}>
                  Summary
                </Text>
                <Text>
                  Some of the workers who attend your Tryout will be
                  disqualified because they are not a good fit for the{' '}
                  {position?.name ?? 'selected'} position. Please invite surplus
                  workers to your Tryout to ensure a full team for your future{' '}
                  {position?.name ?? 'selected'} shifts.
                </Text>
              </Message>
            ) : null}
            {summary.shiftLeads > 0 ? (
              <Heading level={4}>Workers</Heading>
            ) : null}
            <SummaryBox lineItems={lineItems} />
            {summary.shiftLeads > 0 ? (
              <>
                <Heading level={4}>Shift Leads</Heading>
                <SummaryBox lineItems={shiftLeadLineItems} />
              </>
            ) : null}
            <ReviewCallouts />
            {editorError ? (
              <Message mt={4} variant={'error'}>
                {editorError}
              </Message>
            ) : null}
            <Footer ctaText={'Save & Confirm'} />
          </Box>
        </form>
      </Box>
      <Box
        display={['none', 'none', 'none', 'inline-flex']}
        pt={[0, 7]}
        justifyContent={'center'}
        width={[1, 1, 1, 1, 'auto']}
        flex={['auto', 'auto', 'auto', 1]}
      >
        <Preview />
      </Box>
    </Flex>
  )
}
