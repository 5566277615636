import React, { useState } from 'react'
import { Quiz, QuizPage } from '../types'
import IPhone from 'components/IphoneMockup'
import Page from './Page'
import styled from 'styled-components'
import { Button, Flex, SwitchRoot, SwitchThumb, Text } from 'ui'
import { newColors, newDimens } from '../../../core/constants'
import { useFieldArray } from 'react-hook-form'
import { NavButton } from '../Editor/EditableContent'
import { ArrowLeftIcon, ArrowRightIcon } from 'lucide-react'

interface Props {
  data: Quiz
}

export default function QuizWYSIWYGEditor({ data }: Props) {
  const {
    fields: pages,
    insert,
    remove,
  } = useFieldArray<{ pages: Quiz }, 'pages'>({ name: 'pages' })

  const [pageIndex, setPageIndex] = useState(0)
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(true)

  const goPrev = () => setPageIndex((prev) => prev - 1)
  const goNext = () => setPageIndex((prev) => prev + 1)

  const addPage = () => {
    const newPage: QuizPage = {
      key: Math.random().toString(36).substring(2, 12), // random 10 char string
      type: 'radio-group',
      label: 'Modify your new question',
      values: [
        { label: 'Answer A', value: 'Answer A' },
        { label: 'Answer B', value: 'Answer B' },
        { label: 'Answer C', value: 'Answer C' },
        { label: 'Answer D', value: 'Answer D' },
      ],
      correct_value: 'Answer D',
    }
    insert(pageIndex + 1, newPage)
    setPageIndex((prev) => prev + 1)
  }

  const removePage = () => {
    remove(pageIndex)
    if (pageIndex > 0) {
      setPageIndex((prev) => prev - 1)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <NavButton type={'button'} onClick={goPrev} disabled={pageIndex === 0}>
        <ArrowLeftIcon size={16} />
      </NavButton>
      <div>
        <ShowAnswersSwitchContainer>
          <span>Show Correct Answers</span>
          <SwitchRoot
            checked={showCorrectAnswer}
            onCheckedChange={() => setShowCorrectAnswer(!showCorrectAnswer)}
          >
            <SwitchThumb>{showCorrectAnswer ? 'ON' : 'OFF'}</SwitchThumb>
          </SwitchRoot>
        </ShowAnswersSwitchContainer>
        <Flex my={3} alignItems={'center'} justifyContent={'center'}>
          <Button
            variant={'text'}
            kind={'medium'}
            onClick={removePage}
            disabled={pages.length === 1}
            style={{ textDecoration: 'none' }}
            mr={2}
          >
            <Text px={2} color={'error'} fontSize={1}>
              - Delete Page
            </Text>
          </Button>
          <Button
            variant={'text'}
            kind={'medium'}
            onClick={addPage}
            style={{ textDecoration: 'none' }}
          >
            <Text px={2} color={'info'} fontSize={1}>
              + Add Page
            </Text>
          </Button>
        </Flex>
        <IPhone>
          <div>
            {pages.map((page, index) => {
              if (index === pageIndex) {
                return (
                  <Page
                    key={page.key}
                    page={page}
                    pageIndex={pageIndex}
                    showCorrectAnswer={showCorrectAnswer}
                  />
                )
              }
              return null
            })}
          </div>
        </IPhone>
      </div>
      <NavButton
        type={'button'}
        onClick={goNext}
        disabled={pageIndex === pages.length - 1}
      >
        <ArrowRightIcon size={16} />
      </NavButton>
    </div>
  )
}

const ShowAnswersSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${newDimens.small}px;
  justify-content: center;
  align-items: center;
  background-color: ${newColors.lightGray};
  border-radius: ${newDimens.borderRadiusStandard}px;
  padding: ${newDimens.small}px;
`
