import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useShiftTutorial } from 'queries/shift'
import { EditorView } from './EditorView'
import { Loading } from '@workwhile/ui'
import { TutorialCategory } from 'api/shift'
import { Page } from 'pages/layout'
import { HeaderBar } from 'pages/layout/HeaderBar'
import { useCompatTutorial } from 'hooks/useCompatTutorial'
import { EditorProvider } from './EditorProvider'

export function ShiftInstructionEditor() {
  const params = useParams<{ shiftId: string; idOrCategory: string }>()
  const shiftId = Number(params.shiftId)
  const idOrCategory = params.idOrCategory as string
  const tutorialId = useMemo(() => {
    if (!isNaN(Number(idOrCategory))) {
      return Number(idOrCategory)
    }
  }, [idOrCategory])
  const { data: tutorial, isLoading } = useShiftTutorial(shiftId, tutorialId)
  const compatTutorial = useCompatTutorial(tutorial)
  const category = useMemo(() => {
    if (tutorial) {
      return tutorial.category
    }
    return idOrCategory
  }, [idOrCategory, tutorial])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Page>
      <HeaderBar />
      <EditorProvider
        shiftId={shiftId}
        category={category as TutorialCategory}
        tutorialId={tutorialId}
        tutorial={compatTutorial}
      >
        <EditorView />
      </EditorProvider>
    </Page>
  )
}
