import { MarkdownContent, TutorialCategory } from 'api/shift'
import {
  LogInIcon,
  LogOutIcon,
  MegaphoneIcon,
  ParkingSquareIcon,
  ShirtIcon,
} from 'lucide-react'
import { TutorialPage } from 'components/profile/Tutorials/types'
import { path } from 'ramda'

export function getTutorialMetadata(category?: TutorialCategory) {
  switch (category) {
    case TutorialCategory.ArrivalAndParking:
      return {
        title: 'Arrival and Parking',
        description: 'Arrival and Parking Instructions',
        icon: ParkingSquareIcon,
      }
    case TutorialCategory.AttireAndGrooming:
      return {
        title: 'Attire and Grooming',
        description: 'Attire and Grooming Instructions',
        icon: ShirtIcon,
      }
    case TutorialCategory.ClockIn:
      return {
        title: 'Check In',
        description: 'Check In Instructions',
        icon: LogInIcon,
      }
    case TutorialCategory.ClockOut:
      return {
        title: 'Check Out',
        description: 'Check Out Instructions',
        icon: LogOutIcon,
      }
    case TutorialCategory.General:
      return {
        title: 'General Instructions',
        description: 'General Instructions',
        icon: MegaphoneIcon,
      }
    default:
      return null
  }
}

export function convertLegacyTutorialToMarkdown(
  legacyContent: TutorialPage[] | MarkdownContent[]
): MarkdownContent[] {
  return legacyContent.map((page) => {
    // already in new markdown format
    if (path(['markdown'], page)) {
      return page as unknown as MarkdownContent
    }
    const lines = [`# ${page.title}`]
    page.content.forEach((content) => {
      if (content.type === 'subtitle') {
        lines.push(`## ${content.data}`)
      }

      if (content.type === 'paragraph') {
        lines.push(`\n\n${content.data}\n\n`)
      }

      if (content.type === 'bullet_list') {
        lines.push(content.data.map((item) => `- ${item}`).join('\n'))
      }

      if (content.type === 'image_url') {
        // add new line before and after image
        lines.push(`\n&nbsp;`)
        lines.push(`![image](${content.data})`)
        lines.push(`\n&nbsp;`)
      }

      if (content.type === 'pdf_url') {
        lines.push(`\n&nbsp;`)
        lines.push(`[View PDF](${content.data})`)
        lines.push(`\n&nbsp;`)
      }
    })
    return {
      markdown: lines.join('\n'),
    }
  })
}
