import { Tutorial } from "../types";

export const transformLegacyTutorial = (tutorial: any): Tutorial => {
  if (Array.isArray(tutorial) && Array.isArray(tutorial[0])) {
    // flatten array
    const tutorialPages = tutorial.reduce((acc, curr) => [...acc, ...curr], []);
    return tutorialPages as Tutorial;
  }

  return tutorial as Tutorial;
};
