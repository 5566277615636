import React, { ReactNode, useEffect, useMemo, useRef } from 'react'
import { Box, Message, Modal, Pluralize, Text, BaseLink } from 'ui'
import {
  DEFAULT_CANCELLATION_POLICY_WINDOW,
  useCancellationPolicy,
} from 'pages/HomePage/ShiftCard/useCancellationPolicy'
import { useWorkerStats } from 'pages/HomePage/ShiftCard/useWorkerStats'
import { getLocalTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'
import { useShiftEditor } from './useShiftEditor'
import { ShiftEditData } from 'api/shift'
import { track } from 'lib/amplitude'

interface Props {
  trigger: ReactNode
  shift: ShiftEditData
}

const policyUrl =
  'https://storage.googleapis.com/workwhile-terms-docs/static/ww-customer-agreement/ww_customer_agreement_20201204.pdf'

export function ReduceWorkersModal(props: Props) {
  const { shiftData, submitShiftData } = useShiftEditor()
  const { trigger, shift } = props
  const closeModalRef = useRef<() => void>()

  const { policyWindow, isStandard } = useCancellationPolicy(shift)
  const { workers } = useWorkerStats(shift)
  const timezone = shift?.location?.address?.timezone ?? getLocalTimezone()
  const cancellationPolicyWindows =
    shift.cancellationPolicy?.policyWindows ||
    DEFAULT_CANCELLATION_POLICY_WINDOW
  const numWorkersToRemove = useMemo(
    () =>
      shiftData?.staff?.numWorkers
        ? workers.length - shiftData?.staff?.numWorkers
        : 0,
    [shiftData?.staff?.numWorkers, workers.length]
  )

  useEffect(() => {
    const policyData = {
      numWorkersToRemove,
      policyWindowCutoffTimeHours: policyWindow?.cutoffTimeHours,
      policyWindowChargePercentage: policyWindow?.chargePercentage,
    }
    track('impression, reduce_workers_modal_content', policyData)
  }, [numWorkersToRemove, policyWindow])

  const handleSubmit = () => {
    submitShiftData()
  }

  return (
    <Modal
      trigger={trigger}
      title={
        'Are you sure you want to reduce the number of workers on this shift?'
      }
      okText={'Confirm'}
      onOk={({ closeModal }) => {
        closeModalRef.current = closeModal
        handleSubmit()
      }}
    >
      <Box mb={3}>
        {shift.position?.name} shift starting at{' '}
        {formatInTimeZone(shift.startsAt, timezone, 'EEEE MMM dd, hh:mma zzz')}
      </Box>
      <Box mb={3}>
        <Box mb={3}>
          <Text fontWeight={'bold'}>Original</Text> Workers Requested:{' '}
          {shift.workersNeeded}
        </Box>
        <Box>
          <Text fontWeight={'bold'}>New</Text> Workers Requested:{' '}
          {shiftData?.staff?.numWorkers}
        </Box>
      </Box>
      <Box mb={3}>
        <Pluralize
          count={workers.length}
          oneText={'worker'}
          manyText={'workers'}
        />{' '}
        are currently scheduled for this shift.{' '}
        {numWorkersToRemove > 0 ? (
          <>
            {' '}
            <Pluralize
              count={numWorkersToRemove}
              oneText={'worker'}
              manyText={'workers'}
            />{' '}
            will be removed and notified of this cancellation.{' '}
          </>
        ) : null}
      </Box>
      {numWorkersToRemove > 0 && policyWindow ? (
        <>
          <Message variant={'warning'} mb={3}>
            Shift is within {policyWindow.cutoffTimeHours} hours cancellation
            window, you will be charged {policyWindow.chargePercentage}% of each
            removed worker's cost.
          </Message>
          <Box mb={3}>
            <Text fontWeight="bold" mb={2}>
              Your cancellation policy is as follows:
            </Text>
            <ul
              style={{
                listStyle: 'disc',
                marginLeft: '1em',
                marginBottom: '1em',
              }}
            >
              {cancellationPolicyWindows.map((policy) => {
                return (
                  <li
                    key={`policy-${policy.cutoffTimeHours}-${policy.chargePercentage}`}
                  >
                    Cancellations made within {policy.cutoffTimeHours} hours of
                    the shift start time will be charged{' '}
                    {policy.chargePercentage}% of the shift cost.
                  </li>
                )
              })}
            </ul>
          </Box>
          {isStandard ? (
            <Box mt={3}>
              <BaseLink
                onClick={() => {
                  window.open(policyUrl, '_blank')
                }}
                href={policyUrl}
                target={'_blank'}
                rel="noreferrer"
              >
                See policy
              </BaseLink>
            </Box>
          ) : null}
        </>
      ) : null}
    </Modal>
  )
}
