import React, { useMemo } from 'react'
import {
  Flex,
  Box,
  Button,
  Heading,
  Loading,
  Table,
  useResponsiveValue,
  Link,
  toast,
} from 'ui'
import { useCompanyInfo, useCompanyTutorials } from 'queries/company'
import { columns } from './columns'
import { fileDownload } from 'lib/legacy_util'

export function Tutorials() {
  const { data, isLoading } = useCompanyTutorials()
  const { data: company } = useCompanyInfo()

  const tutorials = useMemo(() => {
    if (!data) return []
    return data.sort((a, b) => Number(b.id) - Number(a.id))
  }, [data])

  const columnsToUse = useResponsiveValue([4, 5])

  const ActionButton = ({ company, tutorial }) => {
    const downloadTutorialReportCSV = () => {
      if (!company) return

      const toastId = toast.loading(
        'Please wait a few moments for your tutorial report to download...'
      )
      const url = `/company/${company.id}/tutorial_report/${tutorial.id}`
      fileDownload(url, `workwhile_tutorial_report_${tutorial.id}.csv`).then(() => {
        toast.dismiss(toastId) // Dismiss the loading toast when download is complete.
      })
    }
    return <Button 
    width={1}
    my={[3, 0]}
    kind={'small'}
    onClick={downloadTutorialReportCSV}
    >
      CSV
    </Button>
  }

  const newColumns = useMemo(() => {
    const slicedColumns = columns.slice(0, columnsToUse)
    slicedColumns.push({
      header: ' ',
      cell: ({ row }) => <ActionButton company={company} tutorial={row.original} />
    })
    return slicedColumns
  }, [columns, columnsToUse, company])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box>
      <Flex alignItems={'center'}>
        <Heading level={3}>Tutorials</Heading>
        <Box flex={1} />
        <Link to={'/tutorial/new'}>
          <Button variant={'text'}>+ Add New Tutorial</Button>
        </Link>
      </Flex>
      {tutorials && (
        <Table columns={newColumns} data={tutorials} />
      )}
    </Box>
  )
}