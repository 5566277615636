import React from 'react'
import { Box, createColumnHelper, Text } from 'ui'
import { Location, Position } from 'typings/common_defs'
import { AddressString } from 'components/AddressString'
import { EditLocationButton } from './EditLocationButton'
import { EditPositionButton } from './EditPositionButton'
import { TruncatedDescription } from './TruncatedDescription'

const locationColumnHelper = createColumnHelper<Location>()

export const createLocationColumns = (nameColSize?: number) => [
  locationColumnHelper.accessor('name', {
    header: 'Name',
    size: nameColSize,
    cell: (info) => (
      <>
        <Box pr={3} display={['none', 'none', 'block']}>
          <Text maxLines={2}>{info.getValue()}</Text>
        </Box>
        <Box display={['block', 'block', 'none']} py={2}>
          <Text maxLines={2} fontWeight={2}>
            {info.getValue()}
          </Text>
          <Text>
            {info.row.original.address ? (
              <AddressString address={info.row.original.address} />
            ) : null}
          </Text>
          <Box mt={2}>
            <EditLocationButton locationId={Number(info.row.original.id)} />
          </Box>
        </Box>
      </>
    ),
  }),
  locationColumnHelper.accessor('about', {
    header: 'Address',
    cell: (info) =>
      info.row.original.address ? (
        <AddressString address={info.row.original.address} />
      ) : null,
  }),
  locationColumnHelper.accessor('id', {
    header: '',
    size: 120,
    cell: (info) => (
      <EditLocationButton locationId={Number(info.row.original.id)} />
    ),
  }),
]

const positionColumnHelper = createColumnHelper<Position>()

export const createPositionColumns = (nameColSize?: number) => [
  positionColumnHelper.accessor('name', {
    header: 'Name',
    size: nameColSize,
    cell: (info) => (
      <>
        <Box pr={3} display={['none', 'none', 'block']}>
          <Text maxLines={2}>{info.getValue()}</Text>
        </Box>
        <Box display={['block', 'block', 'none']} py={2}>
          <Text maxLines={2} fontWeight={2}>
            {info.getValue()}
          </Text>
          <TruncatedDescription
            maxLength={140}
            content={info.row.original.about ?? ''}
          />
          <Box mt={2}>
            <EditPositionButton positionId={Number(info.row.original.id)} />
          </Box>
        </Box>
      </>
    ),
  }),
  positionColumnHelper.accessor('about', {
    header: 'Description',
    cell: (info) => (
      <TruncatedDescription content={info.getValue() ?? ''} maxLength={200} />
    ),
  }),
  positionColumnHelper.accessor('id', {
    header: '',
    size: 120,
    cell: (info) => (
      <EditPositionButton positionId={Number(info.row.original.id)} />
    ),
  }),
]
