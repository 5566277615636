import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { StaffForm } from '../../../schemas'
import { useShiftEditor } from '../../../useShiftEditor'
import { EditorMode, ShiftType } from '../../../ShiftEditorProvider'
import { useCompanyInfo } from 'queries/company'
import { isLeadShiftCreationEnabled } from 'lib/flags'
import { Box, Field, Flex, Heading, Input, Paragraph, Select } from 'ui'
import { ShiftLeadInfoModal } from './ShiftLeadInfoModal'
import {
  DEFAULT_SHIFT_LEAD_EXTRA_TIME_DURATION,
  SHIFT_LEAD_EXTRA_TIME_DURATION,
} from 'lib/time'

export function ShiftLeads() {
  const { data: company } = useCompanyInfo()
  const { isNew, editorMode, shiftType, workerToShiftLeadRatio } =
    useShiftEditor()
  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext<StaffForm>()
  const numWorkers = watch('numWorkers') ?? 0
  const numShiftLeads = watch('numShiftLeads') ?? 0
  const shouldShowShiftLeads = useMemo(() => {
    return (
      isNew &&
      editorMode === EditorMode.Standard &&
      shiftType === ShiftType.Regular &&
      numWorkers &&
      Number(numWorkers) >= 20 &&
      company &&
      isLeadShiftCreationEnabled(company)
    )
  }, [isNew, numWorkers, editorMode, shiftType, company])

  if (!shouldShowShiftLeads) {
    return null
  }

  return (
    <Box mt={5}>
      <Heading level={3}>Shift Leads</Heading>
      <Paragraph>
        Shift Leads are experienced workers who can help manage the team. We
        recommend having one Shift Lead for every {workerToShiftLeadRatio}{' '}
        workers. <ShiftLeadInfoModal />
      </Paragraph>
      <Field
        label={'Number of Shift Leads'}
        error={errors.numShiftLeads?.message?.toString()}
      >
        <Input
          {...register('numShiftLeads')}
          aria-label={'Number of Shift Leads'}
          placeholder={`e.g. ${Math.ceil(
            Number(numWorkers) / workerToShiftLeadRatio
          )}`}
        />
      </Field>
      {numShiftLeads > 0 ? (
        <Flex flexDirection={['column', 'row']}>
          <Field
            width={[1, 1 / 2]}
            mr={[0, 2]}
            label={'Early clock in before shift starts'}
            error={errors.shiftLeadEarlyStartTime?.message?.toString()}
          >
            <Controller
              name={'shiftLeadEarlyStartTime'}
              control={control}
              render={({ field }) => (
                <Select
                  isMulti={false}
                  aria-label={'Select Early Start Time'}
                  placeholder={'Select a start time'}
                  defaultValue={DEFAULT_SHIFT_LEAD_EXTRA_TIME_DURATION}
                  value={field.value}
                  options={SHIFT_LEAD_EXTRA_TIME_DURATION}
                  onChange={field.onChange}
                />
              )}
            />
          </Field>
          <Field
            width={[1, 1 / 2]}
            ml={[0, 2]}
            label={'Late clock out after shift ends'}
            error={errors.shiftLeadLateEndTime?.message?.toString()}
          >
            <Controller
              name={'shiftLeadLateEndTime'}
              control={control}
              render={({ field }) => (
                <Select
                  isMulti={false}
                  aria-label={'Select Late End Time'}
                  placeholder={'Select an end time'}
                  defaultValue={DEFAULT_SHIFT_LEAD_EXTRA_TIME_DURATION}
                  value={field.value}
                  options={SHIFT_LEAD_EXTRA_TIME_DURATION}
                  onChange={field.onChange}
                />
              )}
            />
          </Field>
        </Flex>
      ) : null}
    </Box>
  )
}
