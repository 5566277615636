import { CompanyTutorial } from 'api/company'
import { ShiftTutorial } from 'api/shift'
import { convertLegacyTutorialToMarkdown } from 'lib/tutorial'
import { useMemo } from 'react'

export function useCompatTutorial(tutorial?: ShiftTutorial | CompanyTutorial) {
  return useMemo(() => {
    if (!tutorial) {
      return tutorial
    }
    return {
      ...tutorial,
      content: convertLegacyTutorialToMarkdown(tutorial.content),
    }
  }, [tutorial])
}
