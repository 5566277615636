import React, { useEffect } from 'react'
import { track } from 'lib/amplitude'
import { Box, Field, Flex, Heading, Input, Message, RadioGroup, Text } from 'ui'
import { useShiftEditor } from '../../useShiftEditor'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { AudienceType, StaffForm, staffSchema } from '../../schemas'
import { Footer } from '../../Footer'
import { Atom } from 'lucide-react'
import { MdChecklist } from 'react-icons/md'
import { FiUserCheck } from 'react-icons/fi'
import { AudienceTypeOption } from './AudienceTypeOption'
import { Supervisors } from './Supervisors'
import { VscMegaphone } from 'react-icons/vsc'
import { useCompanyPosition } from 'hooks/useCompanyPosition'
import { EditorMode } from '../../ShiftEditorProvider'
import { ShiftLeads } from './ShiftLeads'
import { DEFAULT_SHIFT_LEAD_EXTRA_TIME_DURATION } from 'lib/time'
import { TryoutLabel } from 'components/tryouts/TryoutLabel'

const audienceTypeOptions = [
  {
    value: AudienceType.Default,
    label: (
      <Flex alignItems={'center'}>
        <Atom size={20} />
        <Box ml={2}>Smart Shift Fulfillment</Box>
      </Flex>
    ),
  },
  {
    value: AudienceType.Roster,
    label: (
      <Flex alignItems={'center'}>
        <MdChecklist fontSize={20} />
        <Box ml={2}>Select Lists</Box>
      </Flex>
    ),
  },
  {
    value: AudienceType.SpecificWorkers,
    label: (
      <Flex alignItems={'center'}>
        <FiUserCheck fontSize={20} />
        <Box ml={2}>Request Specific Workers</Box>
      </Flex>
    ),
  },
]

export function StaffPage() {
  useEffect(() => {
    track('impression, ShiftEditor_Team_page')
  }, [])

  const { shiftData, editorMode, isTryout, setShiftData, goNext } =
    useShiftEditor()
  const method = useForm<StaffForm>({
    resolver: zodResolver(staffSchema),
    defaultValues: shiftData?.staff,
  })
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = method
  const position = useCompanyPosition(shiftData?.details?.positionId)
  const isPastShift = editorMode === EditorMode.PastShift

  const onSubmit = handleSubmit((data) => {
    const hasShiftLeads = data.numShiftLeads && data.numShiftLeads > 0
    setShiftData({
      staff: {
        ...data,
        shiftLeadEarlyStartTime: hasShiftLeads
          ? data.shiftLeadEarlyStartTime ??
            DEFAULT_SHIFT_LEAD_EXTRA_TIME_DURATION
          : undefined,
        shiftLeadLateEndTime: hasShiftLeads
          ? data.shiftLeadLateEndTime ?? DEFAULT_SHIFT_LEAD_EXTRA_TIME_DURATION
          : undefined,
      },
    })
    goNext()
  })

  return (
    <Box>
      <Heading level={[2, 1]} my={4}>
        {isTryout ? 'Request Workers For Tryout' : 'Choose Your Staff'}
      </Heading>
      {isTryout ? (
        <Box mb={3}>
          <TryoutLabel />
        </Box>
      ) : null}
      <Heading level={3}>Workers</Heading>
      <Box width={[1, 1, '65rem']}>
        <FormProvider {...method}>
          <form onSubmit={onSubmit}>
            <Field
              label={'Number of Workers'}
              error={errors.numWorkers?.message?.toString()}
            >
              <Input
                {...register('numWorkers')}
                aria-label={'Number of Workers'}
                placeholder={'Enter number of workers'}
              />
            </Field>
            {isTryout ? (
              <Message
                variant={'fixed'}
                py={3}
                icon={<VscMegaphone fontSize={18} />}
              >
                <Text fontWeight={2} mb={2}>
                  Tryout Workers
                </Text>
                <Text>
                  Some of the workers who attend your Tryout will be
                  disqualified because they are not a good fit for the&nbsp;
                  {position?.name ?? 'selected'} position. Please invite surplus
                  workers to your Tryout to ensure a full team for your
                  future&nbsp;
                  {position?.name ?? 'selected'} shifts.
                </Text>
              </Message>
            ) : (
              <Field
                mt={4}
                label={'Configure who has priority for this shift'}
                error={errors.audienceType?.message?.toString()}
              >
                <Controller
                  name={'audienceType'}
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      value={
                        isPastShift
                          ? AudienceType.SpecificWorkers
                          : field.value ?? AudienceType.Default
                      }
                      options={
                        isPastShift
                          ? audienceTypeOptions.slice(2)
                          : audienceTypeOptions
                      }
                      renderOption={AudienceTypeOption}
                    />
                  )}
                />
              </Field>
            )}
            <ShiftLeads />
            <Supervisors />
            <Footer />
          </form>
        </FormProvider>
      </Box>
    </Box>
  )
}
