import React, { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { DragHandle, EditableHeading, EditableP, EditableUl, EditableUrl, RemoveContentButton } from "../Editor/EditableContent";
import { useSortable } from '@dnd-kit/sortable';
import { MdClose, MdDragIndicator } from "react-icons/md";
import { newColors, newDimens } from "components/core/constants";

interface Props {
  id: string;
  keyPath: string;
  onRemove: () => void;
}

const ContentWrapper = styled.div`
  position: relative;
  margin-bottom: ${newDimens.xsmall}px;
  padding-left: ${newDimens.small}px;
  padding-right: ${newDimens.small}px;
  &:hover {
    button {
      visibility: visible;
    }
  }
`;

export default function Content({ keyPath, id, onRemove }: Props) {
  const { register, control, setValue, watch } = useFormContext();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id});

  const [isHovered, setIsHovered] = useState(false);


  const children = useMemo(() => {
    if (watch(`${keyPath}.type`) === "subtitle") {
      return (
        <EditableHeading
          level={3}
          contentEditable={true}
          suppressContentEditableWarning={true}
          {...register(`${keyPath}.data`)}
          onInput={(e) => setValue(`${keyPath}.data`, e.currentTarget.textContent)}
        >
          {watch(`${keyPath}.data`)}
        </EditableHeading>
      );
    }

    if (watch(`${keyPath}.type`) === "paragraph") {
      return (
        <EditableP
          contentEditable={true}
          suppressContentEditableWarning={true}
          {...register(`${keyPath}.data`)}
          onInput={(e) => setValue(`${keyPath}.data`, e.currentTarget.textContent)}
        >
          {watch(`${keyPath}.data`)}
        </EditableP>
      );
    }

    if (watch(`${keyPath}.type`) === "bullet_list") {
      return (
        <EditableUl
          contentEditable={true}
          suppressContentEditableWarning={true}
          {...register(`${keyPath}.data`)}
          onInput={(e) => {
            setValue(`${keyPath}.data`, (e.currentTarget.innerText ?? "").split("\n"));
          }}
        >
          {watch(`${keyPath}.data`).map((bullet, bulletIdx) => (
            <li key={bulletIdx}>{bullet}</li>
          ))}
        </EditableUl>
      );
    }

    if (watch(`${keyPath}.type`) === "image_url" || watch(`${keyPath}.type`) === "pdf_url" || watch(`${keyPath}.type`) === "video_youtube") {
      return <EditableUrl  content={watch(`${keyPath}`)} keyPath={keyPath} />;
    }

    return null;
  }, [watch(`${keyPath}`), keyPath]);

  return (
    <ContentWrapper
    ref={setNodeRef}
    {...attributes}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
  >
    {isHovered && (
      <>
        <DragHandle {...listeners}>
          <MdDragIndicator color={newColors.white} />
        </DragHandle>
        <RemoveContentButton type="button" onClick={onRemove}>
          <MdClose color={newColors.error} style={{overflow: 'visible'}} />
        </RemoveContentButton>
      </>
    )}
    {children}
  </ContentWrapper>
  );
}
