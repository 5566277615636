import { useShiftEditor } from '../../useShiftEditor'
import { useFormContext } from 'react-hook-form'
import { PaymentForm, PaymentTypeEnum } from '../../schemas'
import { useShiftTime } from '../SchedulePage/useShiftTime'
import { useCompanyInfo } from 'queries/company'
import { FALL_BACK_TAKE_PERCENTAGE } from 'api/company'
import { useMemo } from 'react'

export function usePaymentSummary() {
  const { shiftData } = useShiftEditor()
  const { data: company } = useCompanyInfo()
  const wwFeePercent =
    company?.takePercentageEstimate ?? FALL_BACK_TAKE_PERCENTAGE
  const { watch } = useFormContext<PaymentForm>()
  const paymentType = watch('paymentType')
  const pay = Number(watch('pay') || 0)
  const bonus = Number(watch('bonus') || watch('customBonus') || 0)
  const numWorkers = shiftData.staff?.numWorkers ?? 0
  const { durationInMinutesMinusLunch } = useShiftTime({
    date: shiftData.schedule?.selectedDays[0],
    startTime: shiftData.schedule?.startTime?.value,
    endTime: shiftData.schedule?.endTime?.value,
    lunchLength: shiftData.schedule?.lunchLength?.value,
    timezone: shiftData.schedule?.timezone,
  })
  const hours = durationInMinutesMinusLunch
    ? durationInMinutesMinusLunch / 60
    : 0
  const isHourly = paymentType === PaymentTypeEnum.Hourly
  const baseSubtotal = isHourly ? pay * numWorkers * hours : pay * numWorkers
  const baseWWFee = (wwFeePercent / 100) * baseSubtotal
  // shift leads
  const shiftLeadPay = Number(watch('shiftLeadPay') || 0)
  const numShiftLeads = shiftData.staff?.numShiftLeads ?? 0
  const shiftLeadDurationInMinutes = useMemo(() => {
    if (!durationInMinutesMinusLunch) return null

    const shiftLeadEarlyStartTime =
      shiftData.staff?.shiftLeadEarlyStartTime?.value ?? 0
    const shiftLeadLateEndTime =
      shiftData.staff?.shiftLeadLateEndTime?.value ?? 0

    return (
      durationInMinutesMinusLunch +
      shiftLeadEarlyStartTime +
      shiftLeadLateEndTime
    )
  }, [durationInMinutesMinusLunch, shiftData.staff])
  const shiftLeadHours = shiftLeadDurationInMinutes
    ? shiftLeadDurationInMinutes / 60
    : 0
  const shiftLeadBaseSubtotal = isHourly
    ? shiftLeadPay * numShiftLeads * shiftLeadHours
    : shiftLeadPay * numShiftLeads
  const shiftLeadBaseWWFee = (wwFeePercent / 100) * shiftLeadBaseSubtotal
  // totals
  const totalBase =
    baseSubtotal + baseWWFee + shiftLeadBaseSubtotal + shiftLeadBaseWWFee
  const bonusWWFee = (wwFeePercent / 100) * bonus * (numWorkers + numShiftLeads)
  const totalBonus = bonus * (numWorkers + numShiftLeads) + bonusWWFee

  return {
    hasData: !!paymentType && !!pay,
    isHourly,
    pay: Number(pay),
    numWorkers,
    hoursPerShift:
      isHourly && durationInMinutesMinusLunch
        ? Number(durationInMinutesMinusLunch / 60).toFixed(2)
        : null,
    baseWWFee,
    baseWWFeePercent: wwFeePercent,
    totalBase,
    bonusPerWorker: bonus,
    bonusWWFee,
    bonusWWFeePercent: wwFeePercent,
    totalBonus,
    total: totalBase + totalBonus,
    shiftLeadPay,
    // shift leads
    numShiftLeads,
    shiftLeadDurationInMinutes,
    shiftLeadHoursPerShift:
      isHourly && shiftLeadDurationInMinutes
        ? Number(shiftLeadDurationInMinutes / 60).toFixed(2)
        : null,
    shiftLeadBaseWWFee,
  }
}
