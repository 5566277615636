import React from 'react'
import { Box, Button, createColumnHelper, Flex, Text } from 'ui'
import { CompanyTutorial } from 'api/company'
import { CheckIcon, XIcon } from 'lucide-react'
import { useNavigate } from 'react-router'

const columnHelper = createColumnHelper<CompanyTutorial>()


const ActionButtons = ({ tutorial }: { tutorial: CompanyTutorial}) => {
  const navigate = useNavigate()
  return (
    <Flex flexWrap={['wrap', 'nowrap']}>
      <Button
        width={1}
        kind={'small'}
        variant={'text'}
        mr={[0, 3]}
        my={[3, 0]}
        onClick={() => {
          navigate(`/tutorial/new`, {
            state: {
              mode: 'duplicate',
              name: tutorial.name ? `(COPY) ${tutorial.name}` : null,
              content: tutorial.content || null,
              quiz: tutorial.quiz || null,
            },
          })
        }}
      >
        Duplicate
      </Button>
      <Button
        width={1}
        kind={'small'}
        onClick={() =>
          navigate(`/tutorial/${tutorial.id}`, {
            state: {
              mode: 'edit',
              name: tutorial.name,
              content: tutorial.content,
              quiz: tutorial.quiz,
            },
          })
        }
      >
        Edit
      </Button>
    </Flex>
  )
}

export const columns = [
  columnHelper.accessor('id', {
    header: 'ID',
  }),
  columnHelper.accessor('name', {
    header: 'Name',
    cell: (info) => (
      <>
        {info.getValue()}
        <Box display={['block', 'none']}>
          <ActionButtons tutorial={info.row.original} />
        </Box>
      </>
    ),
  }),
  columnHelper.accessor('content', {
    header: 'Lesson',
    cell: (info) =>
      info.getValue() && info.getValue().length > 0 ? (
        <Text color={'success'}>
          <CheckIcon size={24} />
        </Text>
      ) : (
        <Text color={'error'}>
          <XIcon size={24} />
        </Text>
      ),
  }),
  columnHelper.accessor('quiz', {
    header: 'Quiz',
    cell: (info) =>
      info.getValue() && info.getValue().length > 0 ? (
        <Text color={'success'}>
          <CheckIcon size={24} />
        </Text>
      ) : (
        <Text color={'error'}>
          <XIcon size={24} />
        </Text>
      ),
  }),
  columnHelper.display({
    header: ' ',
    size: 260,
    cell: (info) => <ActionButtons tutorial={info.row.original} />,
  }),
]
