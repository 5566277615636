import React, { useEffect } from 'react'
import { useTutorialEditorContext } from './useTutorialEditorContext'
import { Paragraph, Heading, Button } from 'ui'
import { useTheme } from 'styled-components'
import { CheckIcon } from 'lucide-react'

const AddQuiz = () => {
  const { goToTutorialsList, generateQuiz, tutorialData, isGenerating } =
    useTutorialEditorContext()
  const { colors } = useTheme()

  useEffect(() => {
    if (!tutorialData) {
      goToTutorialsList()
    }
  }, [])

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <div
        style={{
          width: 100,
          height: 100,
          borderRadius: 100,
          backgroundColor: colors.primaries[50],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CheckIcon size={50} color={colors.primary} />
      </div>
      <Paragraph py={3}>Success! Your tutorial has been saved.</Paragraph>
      <Heading level={1}>Would you like to add a quiz?</Heading>
      <Paragraph pb={5}>
        We'll use your Tutorial to generate a Quiz that workers can take to test
        their knowledge.
      </Paragraph>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
        <Button
          width={200}
          mr={3}
          loading={isGenerating}
          onClick={generateQuiz}
        >
          YES
        </Button>
        <Button variant={'secondary'} width={200} onClick={goToTutorialsList}>
          NO
        </Button>
      </div>
    </div>
  )
}

export default AddQuiz
