import React from 'react'
import styled, { keyframes } from 'styled-components'
import { useTutorialEditorContext } from './useTutorialEditorContext'
import LunarPhases from '../../../../assets/lunar-phases.gif'
import { Flex, Paragraph } from 'ui'

const AIThinking: React.FC = () => {
  const { stopGenerating } = useTutorialEditorContext()

  const stars = Array.from({ length: 70 }).map((_, i) => {
    const startOpacity = 0.2 // generates random number between 0.5 and 1
    const endOpacity = Math.random() * (0.95 - 0.5) + 0.5 // Ensures a visible change in opacity
    return (
      <AnimatedStar
        key={i}
        style={{
          top: `${Math.random() * 100}vh`,
          left: `${Math.random() * 100}vw`,
        }}
        delay={Math.random() * (2 - 0) + 0}
        startOpacity={startOpacity}
        endOpacity={endOpacity}
      />
    )
  })

  return (
    <Modal>
      {stars}
      <LunarImg width="200px" src={LunarPhases} />
      <Flex flexDirection={'column'} mt={6} mb={3} alignItems={'center'}>
        <Paragraph color={'gray'}>
          Artificial Intelligence is reading your input and constructing your
          content.
        </Paragraph>
        <Paragraph color={'gray'}>Please wait up to 5 minutes.</Paragraph>
      </Flex>
      <CancelButton type="button" onClick={stopGenerating}>
        Stop generating
      </CancelButton>
    </Modal>
  )
}

export default AIThinking

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 0.99) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  z-index: 1000;
  animation: ${fadeIn} 1s ease-out;
`

const LunarImg = styled.img`
  border-radius: 50%;
  animation: ${fadeIn} 2s ease-out;
`

const CancelButton = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

const fly = ({ startOpacity, endOpacity }) => keyframes`
  0% { transform: translate(0, 0); opacity: ${startOpacity}; }
  50% { opacity: ${endOpacity}; }
  100% { transform: translate(50px, 60px); opacity: ${startOpacity}; }
`

const AnimatedStar = styled.div<{
  delay: number
  startOpacity: number
  endOpacity: number
}>`
  width: 2px;
  height: 2px;
  background-color: white;
  position: absolute;
  animation: ${(props) =>
      fly({ startOpacity: props.startOpacity, endOpacity: props.endOpacity })}
    14s linear infinite;
  animation-delay: ${(props) => props.delay}s;
`
