import * as zod from 'zod'
import { detailsSchema } from './detailsSchema'
export * from './detailsSchema'
import { scheduleSchema } from './scheduleSchema'
export * from './scheduleSchema'
import { staffSchema } from './staffSchema'
export * from './staffSchema'
import { paymentSchema } from './paymentSchema'
export * from './paymentSchema'

export const shiftSchema = zod.object({
  details: detailsSchema,
  schedule: scheduleSchema, 
  staff: staffSchema,
  payment: paymentSchema,
})

export const deepPartialShiftSchema = shiftSchema.deepPartial()
export const partialShiftSchema = shiftSchema.partial()

export type ShiftData = zod.infer<typeof shiftSchema>
export type PartialShiftData = zod.infer<typeof partialShiftSchema>
export type DeepPartialShiftData = zod.infer<typeof deepPartialShiftSchema>
