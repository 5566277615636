import { getDemoData, getGraphqlRequest, matchGraphqlRequest } from '../utils'
import { WorkShift } from 'typings/common_defs'
import { differenceInHours } from 'date-fns'

export const mockGetShiftDetails = {
  shouldMock: (request) => matchGraphqlRequest(request, /shiftsDetail\(ids/),
  getResponse: async (request) => {
    const graphqlRequest = getGraphqlRequest(request)
    const demoData = getDemoData()
    const ids = graphqlRequest.variables.ids as number[]

    return {
      data: {
        company: {
          shiftsDetail: ids.map((id) => {
            const shift = demoData.shifts.find(
              (shift) => shift.id === id
            ) as WorkShift
            return {
              ...shift,
              chargeRate: '24.7',
              totalEstimatedCharge: getEstimatedCharge(shift).toFixed(2),
              work: (shift.work ?? []).map((work) => {
                return {
                  ...work,
                  trips:
                    work.status === 'started'
                      ? [
                          {
                            status: 'active',
                            shareUrl: 'https://trck.at/KvM12rm2vW',
                            eta: null,
                            etaSeconds: null,
                            locationStatus: 'traveling',
                            serviceDeviceId:
                              'F0705B6F-32DF-4DA6-A359-619E5225D196',
                          },
                        ]
                      : [],
                }
              }),
            }
          }),
        },
      },
    }
  },
}

function getEstimatedCharge(shift: WorkShift) {
  if (shift.payLumpSum) {
    return Number(shift.payLumpSum) * (shift.workersNeeded ?? 0) * 1.3
  }

  const hours = differenceInHours(
    new Date(shift.endsAt!),
    new Date(shift.startsAt!)
  )
  return Number(shift.payRate) * (shift.workersNeeded ?? 0) * hours * 1.3
}
