import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react'
import {
  MarkdownContent,
  QuizQuestion,
  ShiftTutorial,
  TutorialCategory,
} from 'api/shift'
import { useTemplateShortcut } from './useTemplateShortcut'
import { useLatest } from 'ahooks'
import { getTutorialMetadata } from 'lib/tutorial'
import { useNavigate } from 'react-router'
import { useSaveShiftTutorialMutation } from 'queries/shift'
import { SaveOptionsModal } from 'pages/ShiftInstructionEditor/SaveOptionsModal'
import { convertToLegacyJsonTutorial } from 'pages/ShiftInstructionEditor/convertToLegacyJsonTutorial'
import { cleanUpContent } from 'pages/ShiftInstructionEditor/cleanUpContent'
import { v4 } from 'uuid'
import { toast } from '@workwhile/ui'

interface EditorContextValue {
  tutorialId?: number
  content: MarkdownContent[]
  setContent: React.Dispatch<React.SetStateAction<MarkdownContent[]>>
  quiz: QuizQuestion[] | null
  setQuiz: React.Dispatch<React.SetStateAction<QuizQuestion[] | null>>
  addQuizQuestion: () => void
  currentPageIdx: number
  category: TutorialCategory
  setCurrentPageIdx: (idx: number) => void
  editorDefaultValue: string
  metadata: ReturnType<typeof getTutorialMetadata>
  templateTutorialId: number | null
  error: Error | null
  isSaving: boolean
  showSaveOptionsModal: () => void
  tabValue: string
  setTabValue: React.Dispatch<React.SetStateAction<string>>
}

export const EditorContext = createContext<EditorContextValue | null>(null)

interface EditorProviderProps extends PropsWithChildren {
  shiftId: number
  tutorialId?: number
  category: TutorialCategory
  tutorial?: ShiftTutorial
}

export function EditorProvider(props: EditorProviderProps) {
  const { shiftId, tutorialId, category, tutorial, children } = props
  const [currentPageIdx, setCurrentPageIdx] = useState(0)
  const [editorContent, setEditorContent] = useState(
    tutorial?.content || [{ markdown: '' }]
  )
  const [quiz, setQuiz] = useState(tutorial?.quiz ?? null)
  const latestContent = useLatest(editorContent)
  const templateTutorialId = useTemplateShortcut(setEditorContent)
  const [tabValue, setTabValue] = useState('content')
  const editorDefaultValue = useMemo(() => {
    return latestContent.current[currentPageIdx]?.markdown
  }, [currentPageIdx, templateTutorialId, editorContent.length, tabValue])
  const metadata = useMemo(
    () => getTutorialMetadata(category as TutorialCategory),
    [category]
  )
  const [showOptionsModal, setShowOptionsModal] = useState(false)
  const navigate = useNavigate()
  const {
    mutate: saveTutorial,
    isPending: isSaving,
    error,
  } = useSaveShiftTutorialMutation({
    shiftId,
    tutorialId,
    category,
    onSuccess(tutorialId) {
      toast.success('Instruction saved successfully')
      if (tutorialId) {
        // update route
        navigate(`/shift/${shiftId}/instruction/${tutorialId}`, {
          replace: true,
        })
      }
    },
  })

  const value = useMemo(
    () => ({
      tutorialId,
      content: editorContent,
      setContent: setEditorContent,
      quiz,
      setQuiz,
      addQuizQuestion: () =>
        setQuiz((prev) => {
          const correctValue = v4()
          return [
            ...(prev || []),
            {
              key: v4(),
              type: 'radio-group',
              label: `Quiz Question ${(prev?.length ?? 0) + 1}`,
              values: [
                {
                  label: 'Option 1',
                  value: correctValue,
                },
                {
                  label: 'Option 2',
                  value: v4(),
                },
                {
                  label: 'Option 3',
                  value: v4(),
                },
                {
                  label: 'Option 4',
                  value: v4(),
                },
              ],
              correct_value: correctValue,
            },
          ]
        }),
      currentPageIdx,
      setCurrentPageIdx,
      category,
      editorDefaultValue,
      metadata,
      templateTutorialId,
      isSaving,
      error,
      saveTutorial,
      showSaveOptionsModal: () => setShowOptionsModal(true),
      tabValue,
      setTabValue,
    }),
    [
      tutorialId,
      tutorial,
      currentPageIdx,
      category,
      editorContent,
      editorDefaultValue,
      quiz,
      metadata,
      templateTutorialId,
      error,
      isSaving,
      tabValue,
    ]
  )

  return (
    <EditorContext.Provider value={value}>
      {children}
      {showOptionsModal ? (
        <SaveOptionsModal
          open={showOptionsModal}
          onClose={() => setShowOptionsModal(false)}
          onOk={(propagate: boolean) => {
            setShowOptionsModal(false)
            saveTutorial({
              content: editorContent.map((page) => {
                const jsonContent = convertToLegacyJsonTutorial(page.markdown)
                return {
                  ...page,
                  markdown: cleanUpContent(page.markdown),
                  content: jsonContent,
                }
              }),
              quiz: quiz && quiz.length > 0 ? quiz : undefined,
              propagate,
            })
          }}
        />
      ) : null}
    </EditorContext.Provider>
  )
}
