import { useKeyPress } from 'ahooks'
import { getCompanyTutorials } from 'api/company'
import { convertLegacyTutorialToMarkdown } from 'lib/tutorial'
import { MarkdownContent } from 'api/shift'
import { useState } from 'react'

export function useTemplateShortcut(
  setContent: (content: MarkdownContent[]) => void
) {
  const [templateTutorialId, setTemplateTutorialId] = useState<number | null>(
    null
  )
  useKeyPress('ctrl.l', async () => {
    const templateTutorialId = prompt('Enter template tutorial ID')
    if (templateTutorialId && !isNaN(Number(templateTutorialId))) {
      // fetch template tutorial and set the content
      const tutorials = await getCompanyTutorials()
      const tutorial = tutorials.find(
        (t) => t.id.toString() === templateTutorialId
      )
      if (!tutorial) {
        alert(
          `Template tutorial with ID ${templateTutorialId} not found for this company`
        )
        return
      }
      setTemplateTutorialId(tutorial.id)
      const markdownContent = convertLegacyTutorialToMarkdown(tutorial.content)
      setContent(markdownContent)
    }
  })

  return templateTutorialId
}
