import { useShiftEditor } from '../useShiftEditor'
import { useShiftTime } from '../pages/SchedulePage/useShiftTime'
import { useMemo } from 'react'
import { PaymentTypeEnum } from '../schemas'
import { useShiftSummary } from '../pages/ConfirmPage/useShiftSummary'

export function usePreviewData() {
  const { shiftData } = useShiftEditor()
  const { dates, time } = useShiftSummary()
  const { durationInMinutesMinusLunch } = useShiftTime({
    date: dates[0],
    startTime: shiftData.schedule?.startTime?.value,
    endTime: shiftData.schedule?.endTime?.value,
    lunchLength: shiftData.schedule?.lunchLength?.value,
    timezone: shiftData.schedule?.timezone,
  })
  const estimatedPay = useMemo(() => {
    const pay = shiftData.payment?.pay ?? 0
    const isHourly = shiftData.payment?.paymentType === PaymentTypeEnum.Hourly
    const bonus =
      shiftData.payment?.bonus || shiftData.payment?.customBonus || 0
    if (isHourly) {
      const totalHours = durationInMinutesMinusLunch
        ? durationInMinutesMinusLunch / 60
        : 0
      return pay * totalHours + bonus
    }

    return pay + bonus
  }, [durationInMinutesMinusLunch, shiftData])

  return {
    estimatedPay,
    date: dates[0],
    time,
  }
}
