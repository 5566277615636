import { api, graphql } from 'api'
import { path } from 'ramda'
import { Position, PositionTemplate, Roster } from 'typings/common_defs'
import { toSnakecaseKeys } from 'lib/string'

export async function getCompanyPositions() {
  const data = await graphql(
    `company {
          positions {
            id, name, about, hidden,
            positionTemplateId,
            requirements,
            requirements2 {
              id, category, name
            }
          }
        }`,
    'current_company'
  )

  return path(['data', 'data', 'company', 'positions'], data) as Position[]
}

export interface SimplePositionTemplateGroup {
  id: number
  name: string
  positionTemplates: Array<{ id: number; name: string }>
}

export async function getPositionTemplateGroups() {
  const data = await graphql(
    `
        company {
          positionTemplateGroups {
            id,
            name
            positionTemplates {
              id,
              name
            }
          }
        }
  `,
    'position_template_groups'
  )

  const groups = path(
    ['data', 'data', 'company', 'positionTemplateGroups'],
    data
  ) as SimplePositionTemplateGroup[]

  return groups.map((group) => ({
    ...group,
    id: Number(group.id),
    positionTemplates: group.positionTemplates.map((template) => ({
      ...template,
      id: Number(template.id),
    })),
  }))
}

export async function getPositionTemplate(positionTemplateId: number) {
  const data = await graphql(
    `
        company {
          positionTemplates (positionTemplateId: ${positionTemplateId}) {
            id,
            name,
            requirements {
              isPreSelected,
              isWorkwhileRequired,
              requirement {
                id,
                category,
                name,
                photoUploadNeeded,
              }
            }
          }
        }
      `,
    'position_templates'
  )

  const template = path(
    ['data', 'data', 'company', 'positionTemplates', 0],
    data
  ) as PositionTemplate

  return template
    ? {
        ...template,
        id: Number(template.id),
      }
    : undefined
}

export async function getPositionRoster(positionId: number) {
  const data = await graphql(
    `
    company {
      positions (positionId: ${positionId}) {
        id,
        name,
        roster {
          id
          name
          workers {
            id
            name
            profilePicUrl    
          }
        }
      }
    }
    `,
    'position_roster'
  )

  const roster = path(
    ['data', 'data', 'company', 'positions', 0, 'roster'],
    data
  ) as Roster
  return roster
}

export interface PositionRequest {
  id?: number
  name?: string | null
  about?: string | null
  positionTemplateId?: number
  workerRequirementIds?: Array<number>
  customRequirements?: Array<{ name?: string; category?: string }>
}

export async function createCompanyPosition(
  companyId: number,
  data: PositionRequest
) {
  const response = await api.post(
    `/company/${companyId}/position`,
    toSnakecaseKeys(data)
  )
  return path(['data', 'id'], response)
}

export async function updateCompanyPosition(
  companyId: number,
  positionId: number,
  data: PositionRequest
) {
  await api.put(
    `/company/${companyId}/position/${positionId}`,
    toSnakecaseKeys(data)
  )
}
