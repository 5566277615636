export enum FeatureGates {
  ConfirmLargeHourlyRate = 'confirm_large_hourly_rate',
  RSWV2 = 'rsw_v2',
  EnableClockInOverride = 'enable_clock_in_geofence_override',
  AnonymousProxyTooltip = 'anonymous_phone_proxy',
  BizPortalTutorialPage = 'biz_portal_tutorial_page',
  LeadShiftCreation = 'lead_shift_creation',
  InternalCompanyTreatment = 'internal_company_treatment',
  RSWAvailability = 'rsw_availability',
  BizPortalMinPayPolicySupport = 'biz_portal_min_pay_policy_support',
  EnableNewInstructionsQuiz = 'enable_new_instructions_quiz',
}
