import * as zod from 'zod'

export const selectOptionSchema = {
  label: zod.string().optional(),
  value: zod.string(),
}

const selectOptionObject = zod.object(selectOptionSchema)
export type SelectOption = zod.infer<typeof selectOptionObject>

export const selectNumericOptionSchema = {
  label: zod.string().optional(),
  value: zod.number(),
}
