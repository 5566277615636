import React, { useState } from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { newColors, newDimens } from '../../../core/constants'
import { MdCheck } from 'react-icons/md'
import { EditableHeading, EditableLabel } from '../Editor/EditableContent'
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useFieldArray } from 'react-hook-form'
import { QuizPage } from '../types'
import QuizAnswerDraggableContent from './QuizAnswerDraggableContent'

interface Props {
  page: QuizPage
  pageIndex: number
  showCorrectAnswer: boolean
}

const TutorialContentContainer = styled.div`
  padding: 15px;

  h2 {
    font-size: 1.4rem;
  }

  ul {
    padding-left: 1rem;
    li {
      display: list-item;
      list-style-type: disc;
    }
  }

  p {
    padding: 0.5rem 0;
  }
`

export default function Page({ page, pageIndex, showCorrectAnswer }: Props) {
  const [selectedValue, setSelectedValue] = useState(null)
  const { register, control, setValue, getValues, watch } = useFormContext()

  const keyPath = `pages.${pageIndex}.values` // Changed keyPath to be more specific

  const { fields, move } = useFieldArray({
    control,
    name: keyPath, // Added control and changed name to keyPath
  })

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
  const [activeId, setActiveId] = useState(null)

  const handleDragStart = (event) => {
    const { active } = event
    setActiveId(active.id)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex(
        (_, index) => `item-${index}` === active.id
      )
      const newIndex = fields.findIndex(
        (_, index) => `item-${index}` === over.id
      )
      move(oldIndex, newIndex)
    }

    setActiveId(null)
  }

  return (
    <TutorialContentContainer>
      <EditableHeading
        level={3}
        contentEditable
        suppressContentEditableWarning={true}
        {...register(`pages.${pageIndex}.label`)}
        onInput={(e) =>
          setValue(`pages.${pageIndex}.label`, e.currentTarget.textContent)
        }
      >
        {getValues(`pages.${pageIndex}.label`)}
      </EditableHeading>
      <div
        style={{
          marginTop: '30px',
          display: 'flex',
          flexDirection: 'column',
          gap: newDimens.standard,
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={fields.map((_, index) => `item-${index}`)}
            strategy={verticalListSortingStrategy}
          >
            {fields.map((value, valueIdx) => {
              const quizAnswerValue = watch(`${keyPath}.${valueIdx}.value`)
              const isCorrectAnswer =
                showCorrectAnswer && quizAnswerValue === page.correct_value
              return (
                <QuizAnswerDraggableContent id={`item-${valueIdx}`}>
                  <QuizOption
                    key={`item-${valueIdx}`} // Added key prop
                    id={`item-${valueIdx}`} // Added id prop
                    isCorrectAnswer={isCorrectAnswer}
                  >
                    <InputGroup>
                      <input
                        type="radio"
                        style={{ marginTop: 3 }}
                        value={quizAnswerValue}
                        checked={selectedValue === quizAnswerValue}
                        onChange={handleChange}
                      />
                      <EditableLabel
                        style={{ marginBottom: 0 }}
                        contentEditable
                        suppressContentEditableWarning={true}
                        {...register(`${keyPath}.${valueIdx}.label`)}
                        onInput={(e) =>
                          setValue(
                            `${keyPath}.${valueIdx}.label`,
                            e.currentTarget.textContent
                          )
                        }
                      >
                        {getValues(`${keyPath}.${valueIdx}.label`)}
                      </EditableLabel>
                    </InputGroup>
                    <span style={{ marginTop: 3 }}>
                      {isCorrectAnswer ? (
                        <MdCheck size={14} color={newColors.primary} />
                      ) : null}
                    </span>
                  </QuizOption>
                </QuizAnswerDraggableContent>
              )
            })}
          </SortableContext>
        </DndContext>
      </div>
    </TutorialContentContainer>
  )
}

const InputGroup = styled.div`
  display: flex !important;
  flex-direction: row;
  gap: 5px;
  align-items: flex-start;
`

const QuizOption = styled.div<{ isCorrectAnswer: boolean }>`
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  padding: ${newDimens.xsmall}px;
  background-color: ${(props) =>
    props.isCorrectAnswer ? newColors.primaryLightest : 'none'};
`
