import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Heading, RadioGroup, Text } from '@workwhile/ui'
import IphoneMockup from 'components/IphoneMockup'
import { useEditorContext } from './useEditorContext'

export function QuizPreview() {
  const { quiz } = useEditorContext()
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0)
  const currentQuestion = quiz ? quiz[currentQuestionIdx] : null

  useEffect(() => {
    if (quiz && currentQuestionIdx > quiz.length - 1) {
      setCurrentQuestionIdx(quiz.length - 1)
    }
  }, [quiz, currentQuestionIdx])

  if (!currentQuestion) {
    return (
      <IphoneMockup>
        <Box p={3}>
          <Heading level={3}>No questions added yet</Heading>
          <Text>Add a question to preview</Text>
        </Box>
      </IphoneMockup>
    )
  }

  return (
    <>
      <Flex justifyContent={'center'} mb={3}>
        <Button
          variant={'secondary'}
          kind={'medium'}
          disabled={currentQuestionIdx === 0}
          mr={2}
          onClick={() => setCurrentQuestionIdx((prev) => prev - 1)}
        >
          Prev
        </Button>
        <Button
          variant={'secondary'}
          kind={'medium'}
          disabled={currentQuestionIdx === (quiz?.length ?? 0) - 1}
          ml={2}
          onClick={() => setCurrentQuestionIdx((prev) => prev + 1)}
        >
          Next
        </Button>
      </Flex>
      <IphoneMockup>
        <Box p={3} key={currentQuestionIdx}>
          <Heading level={3}>{currentQuestion.label}</Heading>
          <RadioGroup
            options={currentQuestion.values}
            value={currentQuestion.correct_value}
            name={`question-${currentQuestionIdx}`}
          />
        </Box>
      </IphoneMockup>
    </>
  )
}
