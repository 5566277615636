import { api, graphql } from 'api'

export const generateTutorialFromUserInput = (companyId, data, cancelToken) => {
  const url = `/company/${companyId}/tutorial_assistant`;
  return api.post(url, data, { cancelToken: cancelToken.token }).then((response) => response.data);
};

export const generateQuizFromText = (companyId, text, cancelToken) => {
  const url = `/company/${companyId}/quiz_assistant`;
  const data = { text };
  return api.post(url, data, { cancelToken: cancelToken.token }).then((response) => response.data);
};

export const createTutorialRequest = ({companyId, name}) => {
  const url = `/company/${companyId}/tutorial`;
  const data = { name };
  return api.post(url, data).then((response) => response.data)
}

export const updateTutorialRequest = ({companyId, tutorialId, name, content, quiz}) => {
  const url = `/company/${companyId}/tutorial/${tutorialId}`;
  const data = { name, content, quiz };
  return api.put(url, data).then((response) => response.data);
}

export const companyTutorialsRequest = (tutorialId) => graphql(
   `company {
    tutorials ${tutorialId ? `(tutorialId: ${Number(tutorialId)})` : ""} {
      id, name, content, quiz
    }
  }`
);
