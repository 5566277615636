import React from 'react'
import styled from 'styled-components'
import { newColors, newDimens } from '../../../core/constants'
import { useFormContext } from 'react-hook-form'
import { Heading, Paragraph } from '@workwhile/ui'

const Container = styled.div`
  input {
    border: 1px solid ${newColors.lightGray};
    width: 40rem;
    padding: ${newDimens.xsmall}px;
    border-radius: ${newDimens.borderRadiusStandard}px;
    width: 100%;
    color: ${newColors.blackWithTransparency};

    &:focus {
      border: 1px solid ${newColors.primary}!important;
    }
  }
`

export const EditableUrl = ({ keyPath, content }) => {
  const { register, setValue, watch } = useFormContext()

  const data = watch(`${keyPath}.data`)

  const displayContent = () => {
    switch (content.type) {
      case 'image_url':
        return <img src={data} alt="" style={{ maxWidth: '100%' }} />
      case 'pdf_url':
        return (
          <a href={data} target="_blank" rel="noreferrer">
            {data}
          </a>
        )
      case 'video_youtube':
        return <iframe width="100%" height="150" src={data}></iframe>
      default:
        return null
    }
  }

  return (
    <Container>
      {displayContent()}
      <form>
        <input
          {...register(`${keyPath}.data`)}
          onInput={(e) => setValue(`${keyPath}.data`, e.currentTarget.value)}
        />
      </form>
    </Container>
  )
}

export const EditableHeading = styled(Heading)`
  &:hover {
    background-color: ${newColors.infoLight};
  }
  &:focus {
    border-radius: ${newDimens.borderRadiusStandard}px;
  }
`

export const EditableP = styled(Paragraph)`
  &:hover {
    background-color: ${newColors.infoLight};
  }
  &:focus {
    border-radius: ${newDimens.borderRadiusStandard}px;
  }
`

export const EditableLabel = styled.label`
  &:hover {
    background-color: ${newColors.infoLight};
  }
  &:focus {
    border-radius: ${newDimens.borderRadiusStandard}px;
  }
`

export const EditableUl = styled.ul`
  &:hover {
    background-color: ${newColors.infoLight};
  }
  &:focus {
    border-radius: ${newDimens.borderRadiusStandard}px;
  }
`

export const DragHandle = styled.div`
  position: absolute;
  width: 10px;
  height: 20px;
  left: 3px;
  background-color: ${newColors.gray};
  border-radius: ${newDimens.borderRadiusStandard}px;
  cursor: grab;
  display: flex !important;
  align-items: center;
  justify-content: center;
`

export const RemoveContentButton = styled.button`
  position: absolute;
  width: 14px;
  height: 14px;
  right: 3px;
  border: none;
  background-color: ${newColors.wwErrorLight};
  border-radius: ${newDimens.borderRadiusStandard}px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const NavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 0 2rem;
  cursor: pointer;
  border: 0;
  color: ${newColors.primary};
  background-color: ${newColors.lightGray};

  &[disabled] {
    pointer-events: none;
    opacity: 0.7;
    color: ${newColors.gray};
  }

  &:hover {
    background-color: ${newColors.primary};
    color: white;
  }
`
