import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Quiz, Tutorial } from '../types'
import { useParams } from 'react-router-dom'
import {
  companyTutorialsRequest,
  createTutorialRequest,
  generateQuizFromText,
  generateTutorialFromUserInput,
  updateTutorialRequest,
} from 'actions/company_actions'
import { transformLegacyTutorial } from './helpers'
import { useNavigate, useLocation } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import axios, { CancelTokenSource } from 'axios'
import AIThinking from './AIThinking'
import { useCompanyInfo } from 'queries/company'

export interface EditorContextValueProps {
  isFetching: boolean
  tutorialName: string
  setTutorialName: (text: string) => void
  upsertTutorialName: () => void
  files: File[]
  setFiles: (files: File[]) => void
  rawText: string
  setRawText: (text: string) => void
  tutorialId?: string
  isNew: boolean
  tutorialData: Tutorial | null
  isGenerating: boolean
  tutorialGenerationError: string
  quizGenerationError: string
  generateTutorial: () => void
  saveTutorial: (data: Tutorial) => void
  goToTutorialsList: () => void
  generateQuiz: () => void
  quizData: Quiz | null
  saveQuiz: (data: Quiz) => void
  isSubmitting: boolean
  stopGenerating: () => void
}

export const EditorContext = createContext<EditorContextValueProps>(null!)

interface Props {
  children: ReactNode
}

type Mode = 'new' | 'edit' | 'duplicate'

export const TutorialEditorProvider = React.memo((props: Props) => {
  const { data: company } = useCompanyInfo()
  const companyId = company?.id

  const { tutorialId } = useParams<{ tutorialId: string }>()

  const { data, status, isFetching } = useQuery({
    queryKey: ['tutorial'],
    queryFn: () =>
      companyTutorialsRequest(tutorialId === 'new' ? null : tutorialId),
  })

  const { children } = props
  const [tutorialName, setTutorialName] = useState('')
  const [files, setFiles] = useState<File[]>([])
  const [rawText, setRawText] = useState('')
  const [isGenerating, setGenerating] = useState(false)
  const [tutorialGenerationError, setTutorialGenerationError] = useState('')
  const [tutorialData, setTutorialData] = useState<Tutorial | null>(null)
  const [quizGenerationError, setQuizGenerationError] = useState('')
  const [quizData, setQuizData] = useState<Quiz | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource | null>(null)
  const [mode, setMode] = useState<Mode | null>(null)
  const navigate = useNavigate()
  const location = useLocation()

  const determineMode = () => {
    // explicitly determine mode based on params passed in.
    if (location.state?.mode) {
      setMode(location.state.mode)
      return
    }
    // if there's no mode explicitly passed in, determine based on whether tutorialId is present
    setMode(tutorialId === 'new' ? 'new' : 'edit')
  }

  useEffect(() => {
    determineMode()

    return () => {
      // componentWillUnmount
      setTutorialName('')
      setRawText('')
      setTutorialData(null)
      setQuizData(null)
    }
  }, [])

  useEffect(() => {
    if (mode && mode === 'duplicate') {
      // if we are duplicating an existing tutorial
      // populate the fields with the data we want to duplicate
      const { name, content, quiz } = location.state
      if (name) {
        setTutorialName(name)
      }
      if (content) {
        const parsedContent =
          typeof content === 'string' ? JSON.parse(content) : content
        setTutorialData(
          parsedContent.length > 0
            ? transformLegacyTutorial(parsedContent)
            : null
        )
      }
      if (quiz) {
        const parsedQuiz = typeof content === 'string' ? JSON.parse(quiz) : quiz
        setQuizData(parsedQuiz.length > 0 ? parsedQuiz : null)
      }
    }
  }, [mode])

  useEffect(() => {
    if (tutorialId && tutorialId !== 'new' && data && status == 'success') {
      populateServerTutorialDetails()
    }
  }, [data, status])

  const populateServerTutorialDetails = () => {
    const tutorials = data?.data?.data?.company?.tutorials

    if (tutorials && tutorials.length > 0 && mode && mode !== 'duplicate') {
      // if we're duplicating, we don't want to populate the fields with the server data
      const tutorial = tutorials[0]
      const { name, content, quiz } = tutorial

      setTutorialName(name || '')

      if (content) {
        const parsedContent = JSON.parse(content)
        setTutorialData(
          parsedContent.length > 0
            ? transformLegacyTutorial(parsedContent)
            : null
        )
      } else {
        setTutorialData(null)
      }

      if (quiz) {
        const parsedQuiz = JSON.parse(quiz)
        setQuizData(parsedQuiz.length > 0 ? parsedQuiz : null)
      } else {
        setQuizData(null)
      }
    }
  }

  const generateTutorial = () => {
    if (!rawText && !files.length) {
      navigate(`/tutorial/${tutorialId}/upload`)
      return
    }

    const cancelToken = axios.CancelToken.source()
    setCancelTokenSource(cancelToken)

    const fd = new FormData()

    files.forEach((file, index) => {
      fd.append('file' + index, file, file.name)
    })

    if (rawText) {
      fd.append('text', rawText)
    }

    setGenerating(true)
    setTutorialGenerationError('')
    generateTutorialFromUserInput(companyId, fd, cancelToken)
      .then((tutorial) => {
        setTutorialData(transformLegacyTutorial(tutorial))
        setTutorialGenerationError('')
        navigate(`/tutorial/${tutorialId}/editor`)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request cancelled')
        } else {
          setTutorialGenerationError(error.message)
        }
      })
      .finally(() => {
        setGenerating(false)
      })
  }

  const goToTutorialsList = () => {
    navigate('/profile/tutorials', { replace: true })
  }

  useEffect(() => {
    if (tutorialGenerationError) {
      navigate(`/tutorial/${tutorialId}/tutorial-error`)
    }
  }, [tutorialGenerationError, tutorialId])

  useEffect(() => {
    if (quizGenerationError) {
      navigate(`/tutorial/${tutorialId}/quiz-error`)
    }
  }, [quizGenerationError, tutorialId])

  const upsertTutorialName = () => {
    if (tutorialName) {
      if (tutorialId && tutorialId !== 'new') {
        setIsSubmitting(true)
        updateTutorialRequest({
          companyId: companyId,
          tutorialId: tutorialId,
          name: tutorialName,
          content: null,
          quiz: null,
        })
          .then((data) => {
            if (tutorialData) {
              // if this tutorial already has content
              navigate(`/tutorial/${tutorialId}/editor`)
            } else {
              navigate(`/tutorial/${tutorialId}/upload`)
            }
          })
          .finally(() => {
            setIsSubmitting(false)
          })
      } else {
        setIsSubmitting(true)
        createTutorialRequest({ companyId, name: tutorialName })
          .then((data) => {
            const { id } = data
            if (mode === 'duplicate' && tutorialData) {
              // if we're duplicating (and we have content) we can just skip straight to the editor
              navigate(`/tutorial/${id}/editor`)
            } else {
              navigate(`/tutorial/${id}/upload`)
            }
          })
          .finally(() => {
            setIsSubmitting(false)
          })
      }
    }
  }

  const saveTutorial = (data: Tutorial) => {
    setIsSubmitting(true)
    setTutorialData(data) // make sure state reflects the form context
    updateTutorialRequest({
      companyId: companyId,
      tutorialId: tutorialId,
      content: data,
      name: null,
      quiz: null,
    })
      .then(() => {
        if (quizData) {
          // if this tutorial already has quiz
          navigate(`/tutorial/${tutorialId}/quiz-editor`)
        } else {
          navigate(`/tutorial/${tutorialId}/add-quiz`)
        }
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const generateQuiz = () => {
    if (!tutorialData) {
      return
    }

    const cancelToken = axios.CancelToken.source()
    setCancelTokenSource(cancelToken)

    setGenerating(true)
    setQuizGenerationError('')
    generateQuizFromText(companyId, JSON.stringify(tutorialData), cancelToken)
      .then((quiz) => {
        setQuizData(quiz)
        setQuizGenerationError('')
        navigate(`/tutorial/${tutorialId}/quiz-editor`)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request cancelled')
        } else {
          setQuizGenerationError(error.message)
        }
      })
      .finally(() => {
        setGenerating(false)
      })
  }

  const saveQuiz = (data: Quiz) => {
    setIsSubmitting(true)
    setQuizData(data) // make sure state reflects the form context
    updateTutorialRequest({
      companyId: companyId,
      tutorialId: tutorialId,
      content: null,
      name: null,
      quiz: data,
    })
      .then(() => {
        goToTutorialsList()
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const stopGenerating = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Request cancelled')
      setCancelTokenSource(null)
      setGenerating(false)
    }
  }

  const values = useMemo(
    () => ({
      isFetching,
      files,
      setFiles,
      rawText,
      setRawText,
      tutorialId: tutorialId === 'new' ? undefined : tutorialId,
      isNew: tutorialId === 'new',
      tutorialData: tutorialData,
      isGenerating,
      tutorialGenerationError,
      quizGenerationError,
      generateTutorial,
      saveTutorial,
      goToTutorialsList,
      generateQuiz,
      quizData: quizData,
      saveQuiz,
      isSubmitting,
      tutorialName,
      setTutorialName,
      upsertTutorialName,
      stopGenerating,
    }),
    [
      isFetching,
      files,
      setFiles,
      rawText,
      setRawText,
      tutorialId,
      tutorialData,
      isGenerating,
      tutorialGenerationError,
      quizGenerationError,
      generateTutorial,
      saveTutorial,
      goToTutorialsList,
      generateQuiz,
      quizData,
      saveQuiz,
      isSubmitting,
      tutorialName,
      setTutorialName,
      upsertTutorialName,
      stopGenerating,
    ]
  )

  return (
    <EditorContext.Provider value={values}>
      {children}
      {isGenerating && <AIThinking />}
    </EditorContext.Provider>
  )
})
