import React, { useEffect } from 'react'
import styled from 'styled-components'
import WYSIWIGEditor from '../QuizWYSIWIGEditor'
import { useTutorialEditorContext } from './useTutorialEditorContext'
import { useErrorBoundary } from 'use-error-boundary'
import QuizError from './QuizError'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { Button, Flex, Heading, Message, Paragraph } from 'ui'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export default function QuizEditor() {
  const { quizData, saveQuiz, tutorialId, generateQuiz, isSubmitting } =
    useTutorialEditorContext()
  const navigate = useNavigate()
  const { ErrorBoundary, didCatch } = useErrorBoundary()
  const formData = {
    pages: quizData,
  }
  const methods = useForm({
    defaultValues: formData,
  })
  const {
    formState: { errors },
    handleSubmit,
  } = methods
  const onSubmit = handleSubmit((data) => {
    if (data.pages) {
      saveQuiz(data.pages)
    }
  })

  useEffect(() => {
    if (!quizData) {
      navigate(`/tutorial/${tutorialId}/addQuiz`)
    }
  }, [quizData])

  if (!quizData) {
    return null
  }

  if (didCatch) {
    return <QuizError />
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <ErrorBoundary>
          <Container>
            <Flex>
              <div style={{ flex: 0.5, paddingRight: '3rem' }}>
                <Heading level={2} pb={3}>
                  Review & Edit Quiz
                </Heading>
                <Message variant={'info'} py={3}>
                  This Quiz was generated by Artificial Intelligence and may
                  contain information that is incorrect.
                </Message>
                <Paragraph py={4}>
                  <ol style={{ paddingInlineStart: 0 }}>
                    <li>1. Please review the Quiz carefully.</li>
                    <br />
                    <li>2. To edit, simply click the screen.</li>
                    <br />
                    <li>
                      3. When you're done, please click "Save Quiz" below.
                    </li>
                  </ol>
                </Paragraph>
                <Button type={'submit'} loading={isSubmitting} width={200}>
                  Save Quiz
                </Button>
                <hr style={{ margin: '2rem 0' }} />
                <div style={{ margin: '2rem 0' }}>
                  <Paragraph>Not happy? Ask AI to try again.</Paragraph>
                </div>
                <Button
                  variant={'secondary'}
                  width={200}
                  onClick={generateQuiz}
                >
                  Regenerate
                </Button>
              </div>
              <Flex flexDirection={'column'} style={{ flex: 1 }}>
                <WYSIWIGEditor data={quizData} />
              </Flex>
            </Flex>
          </Container>
        </ErrorBoundary>
      </form>
    </FormProvider>
  )
}
