import React from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import Contents from "./Contents";
import { EditableHeading } from "../Editor/EditableContent";
import { newDimens } from "components/core/constants";

interface Props {
  pageIndex: number;
}

const TutorialContentContainer = styled.div`
  margin-bottom: ${newDimens.large}px; // add some leeway at the bottom

  ul {
    padding-left: 1rem;
    li {
      display: list-item;
      list-style-type: disc;
    }
  }

  p {
    padding: 0.5rem 0;
  }
`;

export default function Page({ pageIndex }: Props) {
  const keyPath = `pages.${pageIndex}`;
  const { register, control, setValue, getValues } = useFormContext();

  return (
    <TutorialContentContainer>
      <div>
        <EditableHeading
          level={2}
          style={{marginLeft: newDimens.small}}
          contentEditable={true}
          suppressContentEditableWarning={true}
          {...register(`${keyPath}.title`)}
          onInput={(e) => setValue(`${keyPath}.title`, e.currentTarget.textContent)}
        >
          {getValues(`${keyPath}.title`)}
        </EditableHeading>
        <Contents keyPath={`${keyPath}.content`} />
      </div>
    </TutorialContentContainer>
  );
}
