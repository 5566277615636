import React, { useMemo } from 'react'
import { Page } from 'pages/layout'
import { Box, Flex, Heading, Loading, Text } from '@workwhile/ui'
import { useParams } from 'react-router-dom'
import { useShiftTutorials } from 'queries/shift'
import {
  AllTutorialCategories,
  MarkdownContent,
  TutorialCategory,
} from 'api/shift'
import { TutorialCard } from './TutorialCard'
import { convertLegacyTutorialToMarkdown } from 'lib/tutorial'

export function ShiftInstructionsPage() {
  const shiftId = Number(useParams<{ shiftId: string }>().shiftId)
  const { data, isFetching } = useShiftTutorials(shiftId)
  const activeTutorials = useMemo(() => {
    if (!data) {
      return []
    }
    return data.map((tutorial) => ({
      ...tutorial,
      content: convertLegacyTutorialToMarkdown(tutorial.content),
    }))
  }, [data])
  const inactiveTutorials = useMemo(
    () =>
      AllTutorialCategories.filter(
        (category) => !activeTutorials.some((t) => t.category === category)
      ).map((category) => ({
        category: category as TutorialCategory,
        content: [] as MarkdownContent[],
      })),
    [activeTutorials]
  )

  if (isFetching) {
    return <Loading />
  }

  return (
    <Page title={'Shift Instructions'}>
      <Text>
        Select any of the instruction categories below to add to your shift.
      </Text>
      {activeTutorials.length > 0 ? (
        <Box mt={4}>
          <Heading level={3}>Active</Heading>
          <Flex mx={-2} flexWrap={'wrap'}>
            {activeTutorials.map((tutorial) => (
              <Box
                key={tutorial.id}
                width={[1, 1 / 2, 1 / 2, 1 / 3, 1 / 4]}
                p={2}
              >
                <TutorialCard data={tutorial} />
              </Box>
            ))}
          </Flex>
        </Box>
      ) : null}
      {inactiveTutorials.length > 0 ? (
        <Box mt={4}>
          {activeTutorials.length > 0 ? (
            <Heading level={3}>Inactive</Heading>
          ) : null}
          <Flex mx={-2} flexWrap={'wrap'}>
            {inactiveTutorials.map((tutorial) => (
              <Box
                key={tutorial.category}
                width={[1, 1 / 2, 1 / 2, 1 / 3, 1 / 4]}
                p={2}
              >
                <TutorialCard data={tutorial} />
              </Box>
            ))}
          </Flex>
        </Box>
      ) : null}
    </Page>
  )
}
