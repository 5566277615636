import React from 'react'
import { Button, Flex, Message } from '@workwhile/ui'
import { useEditorContext } from './useEditorContext'

export function SaveBox() {
  const { error, isSaving, showSaveOptionsModal } = useEditorContext()

  return (
    <>
      {error ? (
        <Message mt={5} mb={-4} variant={'error'}>
          {error.message}
        </Message>
      ) : null}
      <Flex justifyContent={'center'} mt={5}>
        <Button width={200} loading={isSaving} onClick={showSaveOptionsModal}>
          Save
        </Button>
      </Flex>
    </>
  )
}
