import React, { useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { MdDragIndicator } from 'react-icons/md'
import { newDimens } from 'components/core/constants'
import styled from 'styled-components'

const QuizAnswerDraggableContent = ({
  id,
  children,
}: {
  id: string
  children: React.ReactNode
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })
  const [isHovered, setIsHovered] = useState(false)

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <DraggableContentWrapper
      ref={setNodeRef}
      {...attributes}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={style}
    >
      {isHovered && (
        <div {...listeners}>
          <MdDragIndicator />
        </div>
      )}
      {children}
    </DraggableContentWrapper>
  )
}

export default QuizAnswerDraggableContent

const ContentWrapper = styled.div`
  position: relative;
  margin-bottom: ${newDimens.xsmall}px;
  padding-left: ${newDimens.small}px;
  padding-right: ${newDimens.small}px;
  &:hover {
    button {
      visibility: visible;
    }
  }
`
const DraggableContentWrapper = styled(ContentWrapper)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: grab;
`
