import React, { useState } from 'react'
import { Tutorial, TutorialPage } from '../types'
import { useFieldArray } from 'react-hook-form'
import IPhone from 'components/IphoneMockup'
import Page from './Page'
import { ArrowLeftIcon, ArrowRightIcon } from 'lucide-react'
import { NavButton } from '../Editor/EditableContent'
import { Flex, Button, Text } from 'ui'

interface Props {
  data: Tutorial
}

export default function TutorialWYSIWIGEditor({ data }: Props) {
  const {
    fields: pages,
    insert,
    remove,
  } = useFieldArray<{ pages: Tutorial }, 'pages'>({
    name: 'pages',
  })

  const [pageIndex, setPageIndex] = useState(0)

  const goPrev = () => setPageIndex((prev) => prev - 1)
  const goNext = () => setPageIndex((prev) => prev + 1)

  const addPage = () => {
    const newPage: TutorialPage = {
      title: 'New Page',
      content: [
        {
          type: 'subtitle',
          data: "This is your new page, feel free to make any edits you'd like",
        },
      ],
    }
    insert(pageIndex + 1, newPage)
    setPageIndex((prev) => prev + 1)
  }

  const removePage = () => {
    remove(pageIndex)
    if (pageIndex > 0) {
      setPageIndex((prev) => prev - 1)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <NavButton type={'button'} onClick={goPrev} disabled={pageIndex === 0}>
        <ArrowLeftIcon size={16} />
      </NavButton>
      <div>
        <Flex mb={3} alignItems={'center'} justifyContent={'center'}>
          <Button
            variant={'text'}
            kind={'medium'}
            onClick={removePage}
            disabled={pages.length === 1}
            style={{ textDecoration: 'none' }}
            mr={2}
          >
            <Text px={2} color={'error'} fontSize={1}>
              - Delete Page
            </Text>
          </Button>
          <Button
            variant={'text'}
            kind={'medium'}
            onClick={addPage}
            style={{ textDecoration: 'none' }}
          >
            <Text px={2} color={'info'} fontSize={1}>
              + Add Page
            </Text>
          </Button>
        </Flex>
        <IPhone>
          <div>
            {pages.map((page, index) => {
              if (index === pageIndex) {
                return <Page key={page.title} pageIndex={pageIndex} />
              }
              return null
            })}
          </div>
        </IPhone>
      </div>
      <NavButton
        type={'button'}
        onClick={goNext}
        disabled={pageIndex === pages.length - 1}
      >
        <ArrowRightIcon size={16} />
      </NavButton>
    </div>
  )
}
