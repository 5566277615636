import React, { useState } from 'react'
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalCtaContainer,
  RadioGroup,
} from '@workwhile/ui'

interface Props {
  open: boolean
  isRemoving?: boolean
  onOk: (propagate: boolean) => void
  onClose: () => void
}

export function SaveOptionsModal({ open, onOk, onClose, isRemoving }: Props) {
  const [propagate, setPropagate] = useState<boolean | null>(null)

  const handleSave = () => {
    if (propagate !== null) {
      onOk(propagate)
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box pb={4}>
        <Heading level={3}>
          {isRemoving
            ? 'Which Shifts Would You Like to Remove This Instruction From?'
            : 'How Would You Like to Save This Instruction?'}
        </Heading>
        <RadioGroup
          options={[
            {
              label: 'This Shift Only',
              value: 'this_shift_only',
            },
            {
              label: 'This Shift and Future Shifts',
              value: 'apply_to_other_shifts',
            },
          ]}
          name={'propagate'}
          onChange={(value) => setPropagate(value !== 'this_shift_only')}
        />
      </Box>
      <ModalCtaContainer>
        <Button variant={'text'} onClick={onClose} mr={3}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={propagate === null}>
          {isRemoving ? 'Select & Remove' : 'Select & Save'}
        </Button>
      </ModalCtaContainer>
    </Modal>
  )
}
