import React from 'react'
import { useTutorialEditorContext } from './useTutorialEditorContext'
import { Box, Button, Heading, Input, Loading } from 'ui'

const Details = () => {
  const {
    isFetching,
    tutorialName,
    setTutorialName,
    upsertTutorialName,
    isSubmitting,
  } = useTutorialEditorContext()

  if (isFetching) {
    return <Loading />
  }

  return (
    <>
      <Heading level={2}>Name your Tutorial</Heading>
      <Box mb={3} width={[1, 300]}>
        <Input
          block={true}
          placeholder={'e.g. Warehouse Associate Tutorial'}
          value={tutorialName}
          onChange={(e) => setTutorialName(e.target.value)}
        />
      </Box>
      <Button
        width={100}
        type={'submit'}
        onClick={upsertTutorialName}
        loading={isSubmitting}
      >
        Save
      </Button>
    </>
  )
}

export default Details
