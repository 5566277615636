import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTutorialEditorContext } from './useTutorialEditorContext'
import styled from 'styled-components'
import { Paragraph, Heading, Button, Box, Textarea } from 'ui'

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused || props.isHovered) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const StyledDropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.5);
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 100%;
  height: 100px;
  max-width: 500px;
  cursor: pointer;
`

const FileUploader: React.FC = () => {
  const [mode, setMode] = useState<'file' | 'text'>('file')
  const {
    files,
    setFiles,
    rawText,
    setRawText,
    generateTutorial,
    isGenerating,
  } = useTutorialEditorContext()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles)
    },
  })

  const handleRemoveFile = (index: number) => {
    setFiles(files.filter((file, i) => i !== index))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    generateTutorial()
  }

  const handleModeChange = (mode: 'file' | 'text') => {
    setMode(mode)
    if (mode === 'text') {
      setFiles([])
    } else if (mode === 'file') {
      setRawText('')
    }
  }

  return (
    <Box>
      <form onSubmit={onSubmit}>
        {mode === 'file' ? (
          <>
            <Heading level={2}>Upload PDFs</Heading>
            <Paragraph>
              Add PDFs about your job and let our ✨AI✨ generate a tutorial for
              you
            </Paragraph>
            <StyledDropzone {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <Paragraph>Drop the PDF files here ...</Paragraph>
              ) : (
                <Paragraph>
                  Drag 'n' drop some PDF files here, or click to select files
                </Paragraph>
              )}
            </StyledDropzone>
            <Paragraph
              style={{
                color: 'rgba(0,0,0, 0.5)',
                fontStyle: 'italic',
                fontSize: 12,
              }}
            >
              Your file must be a PDF smaller than 35MB.
            </Paragraph>
            <Box my={2}>
              {files.length > 0 ? (
                <>
                  <Heading level={4}>Uploaded Files:</Heading>
                  {files.map((file, index) => (
                    <li key={file.name}>
                      {file.name}{' '}
                      <button onClick={() => handleRemoveFile(index)}>
                        Remove
                      </button>
                    </li>
                  ))}
                </>
              ) : null}
            </Box>
            <a onClick={() => handleModeChange('text')}>Enter Text instead</a>
          </>
        ) : null}

        {mode === 'text' ? (
          <>
            <Heading level={2}>Insert Text</Heading>
            <Paragraph>
              Paste information about the job in the box below and let our
              ✨AI✨ generate a tutorial for you
            </Paragraph>
            <Box mb={2} width={'40rem'}>
              <Textarea
                minRows={20}
                value={rawText}
                onChange={(e) => setRawText(e.target.value)}
              />
            </Box>
            <a onClick={() => handleModeChange('file')}>Upload PDFs instead</a>
          </>
        ) : null}
        <Box mt={3}>
          <Button type={'submit'} loading={isGenerating}>
            Generate Tutorial
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default FileUploader
